import React from "react";
import { getMoneyPattern } from "../../utils/regex";
import { UsersIcon } from "@heroicons/react/24/outline";

const TotalCard = ({ title, content, icon, isMoney = false }) => {
  return (
    <div className='grid grid-cols-5 w-full bg-white dark:bg-zinc-800 border-b-2 border-zinc-400/70 dark:border-zinc-950 rounded-3xl px-4 py-5'>
      <div className='flex flex-col col-span-3'>
        <span className='text-[14px] font-inter font-medium text-neutral-600 dark:text-neutral-400'>
          {title}
        </span>
        <span
          className={
            (content + "").length > 5
              ? "text-[22px] font-space font-bold text-black dark:text-white"
              : "text-[28px] font-space font-bold text-black dark:text-white"
          }
        >
          {isMoney
            ? `${getMoneyPattern(content, ",")} UZS`
            : getMoneyPattern(content, ",")}
        </span>
      </div>
      <div className='flex flex-col col-span-2 items-end'>{icon}</div>
    </div>
  );
};

export default TotalCard;
