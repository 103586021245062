import React, { useEffect } from "react";
import ProTable from "../../components/ProTable";
import { useDispatch, useSelector } from "react-redux";
import {
  fields,
  emptyValues,
  validationSchema,
  columns,
  INITIAL_VISIBLE_COLUMNS,
  searchIndexes,
} from "./data";
import {
  createBranch,
  deleteBranch,
  getAllBranches,
  updateBranch,
} from "./branchesSlice";
import { changeBC } from "../DashboardPage/dashboardSlice";
import { BASE_URL } from "../../config/Api";

const BranchesPage = () => {
  const dispatch = useDispatch();
  const { branches } = useSelector((state) => state.branches);

  useEffect(() => {
    dispatch(changeBC([{ name: "Филиаллар", link: "/branches" }]));

    dispatch(getAllBranches());
  }, []);

  return (
    <div className='flex flex-col w-full px-5 gap-8 h-full'>
      <div className='tableContent'>
        {branches && (
          <ProTable
            searchIndexes={searchIndexes}
            exportData={{
              link: `/filial/export`,
              fileName: "export_branches.xlsx",
            }}
            // viewButtonUrl='/branches'
            createSubmitHandler={(reqBody) =>
              dispatch(
                createBranch({
                  currentSalary: +reqBody?.currentSalary,
                  ...reqBody,
                })
              )
            }
            editSubmitHandler={(reqBody) => dispatch(updateBranch(reqBody))}
            deleteSubmitHandler={(id) => dispatch(deleteBranch({ id }))}
            columns={columns}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
            tableData={branches}
            createData={{
              fields,
              initialValues: emptyValues,
              validationSchema,
            }}
            editData={{
              fields,
              initialValues: (data) => data,
              validationSchema,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default BranchesPage;
