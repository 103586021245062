import React, { useCallback, useEffect, useState } from "react";
import PDPage from "./PDPage";
import { Tab, Tabs } from "@nextui-org/react";
import CDPage from "./CDPage";

const ProfilePdPage = () => {
  const [selectedKey, setSelectedKey] = useState("pd");

  return (
    <div className='flex flex-col items-center w-full gap-3 h-full'>
      <Tabs color='primary' onSelectionChange={setSelectedKey}>
        <Tab key='pd' title='Материал ҳисоби' />
        <Tab key='cd' title='Клиент ҳисоби' />
      </Tabs>
      {selectedKey === "pd" ? <PDPage /> : <CDPage />}
    </div>
  );
};

export default ProfilePdPage;
