import React, { useEffect, useState } from "react";
import LoginPage from "./pages/Login/LoginPage";

import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import {
  AuthenticatedRoutes,
  ProtectRoutes,
} from "./config/protectedRoutes.jsx";
import store from "./store/store";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import DashboardPage from "./pages/DashboardPage/DashboardPage";

const App = () => {
  const [isDark, setIsDark] = useState(false);

  const userTheme = localStorage.getItem("theme");
  const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
  useEffect(() => {
    themeCheck();
  }, []);
  const themeCheck = () => {
    if (userTheme === "dark" || (!userTheme && systemTheme)) {
      document.documentElement.classList.add("dark");

      setIsDark(true);
      return;
    }

    setIsDark(false);
  };
  return (
    <Provider store={store}>
      <NextThemesProvider attribute='class' defaultTheme='dark'>
        <BrowserRouter>
          <Routes>
            <Route element={<ProtectRoutes />}>
              <Route path='/*' element={<DashboardPage />} />
            </Route>
            <Route element={<AuthenticatedRoutes />}>
              <Route path='/login' element={<LoginPage />} />
            </Route>
          </Routes>
          <ToastContainer
            // style={{ fontWeight: 500, color: "black" }}
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
          />
        </BrowserRouter>
      </NextThemesProvider>
    </Provider>
  );
};

export default App;
