import { MoneyReceive02Icon } from "hugeicons-react";
import React from "react";
import { getMoneyPattern } from "../../utils/regex";

const BranchCard = ({ branch, icon, title, amountStyle }) => {
  return (
    <div className='flex flex-col w-full bg-white dark:bg-zinc-800 border-b-2 border-zinc-400/70 dark:border-zinc-950 rounded-3xl px-4 py-5'>
      <div className='flex flex-col gap-2 '>
        <div className='flex flex-row items-center gap-3'>
          {icon}
          <span className='text-black dark:text-zinc-300 text-[16px] font-semibold font-space'>
            {title}
          </span>
        </div>
        <span className='text-black dark:text-zinc-300 text-[14px] font-semibold font-space'>
          {branch?.filialName}
        </span>
        <span className={amountStyle}>
          {`${getMoneyPattern(branch?.totalAmount, ",")} UZS`}
        </span>
      </div>
    </div>
  );
};

export default BranchCard;
