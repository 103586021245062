import * as Yup from "yup";

export const fields = [
  {
    name: "newPayment",
    type: "text",
    label: "Тўлов",
    placeholder: "Тўловни киритинг...",
    isRequired: true,
  },

  // {
  //   name: "reportId",
  //   type: "customSelect",
  //   label: "Filial",
  //   placeholder: "Filialni tanlang...",
  //   isRequired: true,
  // },
];

export const emptyValues = {
  newPayment: "",
  // number: "",
  // square_meters: "",
  // price: "",
  // floor: 0,
  // block: "",
  // filialId: "",
  // currentSalary: 0,
};

export const validationSchema = Yup.object().shape({
  newPayment: Yup.string().required("Бўш бўлмаслиги керак!"),
  // number: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
  // square_meters: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
  // price: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
  // floor: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
  // block: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  // filialId: Yup.mixed().required("Bo'sh bo'lmasligi kerak!"),

  // currentSalary: Yup.string()
  //   .matches(/^\d+$/, "Iltimos raqam kiriting!")
  //   .required("Joriy maosh bo'sh bo'lmasligi kerak!"),
});

export const columns = [
  { name: "ID", uid: "id", sortable: true },

  { name: "Тўлов миқдори", uid: "newPayment" },
  // { name: "Raqami", uid: "number", sortable: true },
  // { name: "Kvadrat metri", uid: "square_meters", sortable: true },
  // { name: "Narxi", uid: "price", sortable: true },
  // { name: "Qavat", uid: "floor", sortable: true },
  // { name: "Blok", uid: "block", sortable: true },

  { name: "Санаси", uid: "createdAt" },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "newPayment",
  // "number",
  // "square_meters",
  // "price",
  // "floor",
  // "block",

  "createdAt",
  "actions",
];

export const searchIndexes = [
  "newPayment",
  // "number",
  // "square_meters",
  // "price",
  // "floor",
  // "block",
];
