import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../pages/Login/authSlice";
import userReducer from "../pages/UsersPage/usersSlice";
import notificationReducer from "../pages/NotificationsPage/notificationsSlice";
import branchReducer from "../pages/BranchesPage/branchesSlice";
import reportReducer from "../pages/ReportPage/reportSlice";
import dashboardReducer from "../pages/DashboardPage/dashboardSlice";
// import categoryReducer from "../pages/Categories/categoriesSlice";
// import incomeReducer from "../pages/IncomesPage/incomesSlice";
// import outcomeReducer from "../pages/OutcomesPage/outcomesSlice";
import employeeReducer from "../pages/EmployeesPage/employeesSlice";
import profilePDReducer from "../pages/PDPage/pdSlice";
import profileCDReducer from "../pages/PDPage/cdSlice";
import statisticsReducer from "../pages/StatisticsPage/statisticsSlice";
// import saleStoreReducer from "../pages/AgreementsPage/saleStoresSlice";
// import rentStoreReducer from "../pages/AgreementsPage/rentStoreSlice";
// import storeReducer from "../pages/StoresPage/storesSlice";
// import adsItemReducer from "../pages/AdsPage/adsItemsSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    branches: branchReducer,
    report: reportReducer,
    dashboard: dashboardReducer,
    // categories: categoryReducer,
    // incomes: incomeReducer,
    // outcomes: outcomeReducer,
    employees: employeeReducer,
    profilePD: profilePDReducer,
    profileCD: profileCDReducer,
    statistics: statisticsReducer,
    notifications: notificationReducer,
    // saleStores: saleStoreReducer,
    // rentStores: rentStoreReducer,
    // stores: storeReducer,
    // adsItems: adsItemReducer,
    // inventory: inventoryReducer,
  },
});
