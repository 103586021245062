import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeBC } from "../DashboardPage/dashboardSlice";
import SentNotificationsPage from "./SentNotificationsPage";
import SendNotificationsPage from "./SendNotificationsPage";
import { Tab, Tabs } from "@nextui-org/react";
import { Note03Icon, SentIcon } from "hugeicons-react";

const NotificationsPage = () => {
  const [selectedKey, setSelectedKey] = useState("table");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeBC([{ name: "Билдиришномалар", link: "/notifications" }]));
  }, []);
  return (
    <div className='flex flex-col items-center w-full gap-3 h-full mt-[-10px]'>
      <Tabs color='primary' onSelectionChange={setSelectedKey}>
        <Tab
          key='table'
          title={
            <div className='flex items-center space-x-2'>
              <Note03Icon />
              <span>{"Жўнатилганлар"}</span>
            </div>
          }
        />
        <Tab
          key='send'
          title={
            <div className='flex items-center space-x-2'>
              <SentIcon />
              <span>{"Билдиришнома юбориш"}</span>
            </div>
          }
        />
      </Tabs>
      {selectedKey === "table" ? (
        <SentNotificationsPage />
      ) : (
        <SendNotificationsPage />
      )}
    </div>
  );
};

export default NotificationsPage;
