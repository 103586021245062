export const getOptions = (theme) => ({
  responsive: true,
  scales: {
    x: {
      title: {
        display: false,
      },
      grid: {
        display: false,
      },
      ticks: {
        color: theme === "dark" ? "#fff" : "#2D3748",
      },
    },
    y: {
      title: {
        display: false,
      },
      min: 0,
      // max: 80000,
      ticks: {
        // stepSize: 20000,
        color: theme === "dark" ? "#fff" : "#2D3748",

        // callback: function (value) {
        //   return value * 0.001 + " K";
        // },
      },
      grid: {
        color: theme === "dark" ? "#5e5e5e" : "#EDF2F7",
      },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        font: {
          size: 12,
          weight: 500,
        },
        color: theme === "dark" ? "#fff" : "#2D3748",
        boxWidth: 8,
        boxHeight: 8,
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
    // title: {
    //   display: true,
    //   text: "Chart.js Line Chart",
    // },
  },
});
