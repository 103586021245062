export const getMonthName = (num) => {
  switch (num) {
    case 0:
      return "Yanvar";
    case 1:
      return "Fevral";
    case 2:
      return "Mart";
    case 3:
      return "Aprel";
    case 4:
      return "May";
    case 5:
      return "Iyun";
    case 6:
      return "Iyul";
    case 7:
      return "Avgust";
    case 8:
      return "Sentabr";
    case 9:
      return "Oktabr";
    case 10:
      return "Noyabr";
    case 11:
      return "Dekabr";
    default:
      return ""; // You can handle invalid inputs here
  }
};

export const getActivityDate = (date) => {
  const activityDate = new Date(date);
  const now = new Date();

  if (now.getFullYear() == activityDate.getFullYear()) {
    if (now.getMonth() == activityDate.getMonth()) {
      if (now.getDate() == activityDate.getDate()) {
        if (now.getHours() == activityDate.getHours()) {
          if (now.getMinutes() == activityDate.getMinutes()) {
            if (now.getMilliseconds() == activityDate.getMilliseconds()) {
              return `0 sekund oldin`;
            }
            return `${
              now.getSeconds() - activityDate.getSeconds()
            } sekund oldin`;
          }
          return `${now.getMinutes() - activityDate.getMinutes()} daqiqa oldin`;
        }
        return `${now.getHours() - activityDate.getHours()} soat oldin`;
      }
      return `${now.getDate() - activityDate.getDate()} kun oldin`;
    }
    return `${now.getMonth() - activityDate.getMonth()} oy oldin`;
  }
  return `${now.getFullYear() - activityDate.getFullYear()} yil oldin`;
};

export function FormatDate(date) {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  let day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}
