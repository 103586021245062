export const columns = [
  { name: "ID", uid: "id", sortable: true },
  { name: "Фамилияси", uid: "surname", sortable: true },
  { name: "Исми", uid: "name", sortable: true },
  { name: "Тел. рақами", uid: "phoneNumber", sortable: true },

  //   { name: "Role", uid: "user.role", sortable: true },
  //   //   { name: "FAMILIYA", uid: "user.lastName", sortable: true },
  //   //   { name: "OTASINING ISMI", uid: "user.fatherName", sortable: true },
  //   //   { name: "Status", uid: "user.status", sortable: true },
  //   { name: "TEL. RAQAM", uid: "user.phoneNumber", sortable: true },

  // { name: "STATUS", uid: "status", sortable: true },
  // { name: "ACTIONS", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "name",
  "surname",
  "phoneNumber",
  "roles",
  "phoneNumber",
];

export const searchIndexes = [
  "name",
  "surname",
  "roles",
  //   ["name"],
  //   ["lastName"],
  //   ["fatherName"],
  //   ["phoneNumber"],
];
