import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeBC } from "../DashboardPage/dashboardSlice";
import { DateRangePicker } from "@nextui-org/react";
import {
  getAllHisobot,
  getAllHisobotByBranchId,
  getAllHisobotByBranchIdByDateRange,
  getAllHisobotByDateRange,
  getAllHisobotByDateRangeManager,
  getAllHisobotManager,
} from "../StatisticsPage/statisticsSlice";
import ProTable from "../../components/ProTable";
import { columns, INITIAL_VISIBLE_COLUMNS, searchIndexes } from "./data";

const HisobotPage = () => {
  const dispatch = useDispatch();
  const { hisobot } = useSelector((state) => state.statistics);
  const { user } = useSelector((state) => state.auth);

  const { chosenBranch } = useSelector((state) => state.branches);

  useEffect(() => {
    if (user?.roles === "ADMIN") {
      if (chosenBranch) {
        dispatch(getAllHisobotByBranchId({ filialId: chosenBranch?.id }));
      } else {
        dispatch(getAllHisobot());
      }
    } else {
      dispatch(getAllHisobotManager());
    }
  }, [user]);
  useEffect(() => {
    dispatch(changeBC([{ name: "Ҳисобот", link: "/report" }]));
  }, []);
  return (
    <div className='flex flex-col gap-2 px-5'>
      <DateRangePicker
        // defaultValue={{
        //   start: parseDate("2024-07-01"),
        //   end: parseDate("2024-08-08"),
        // }}
        visibleMonths={2}
        label='Вақт оралиғини танлаш'
        //   value={value}
        onChange={(date) => {
          if (date) {
            //   dispatch(clearIncomes());
            //   dispatch(clearOutcomes());
            if (user?.roles === "ADMIN") {
              if (chosenBranch) {
                dispatch(
                  getAllHisobotByBranchIdByDateRange({
                    startDate: new Date(date?.start).toISOString(),
                    endDate: new Date(date?.end).toISOString(),
                    filialId: chosenBranch?.id,
                  })
                );
              } else {
                dispatch(
                  getAllHisobotByDateRange({
                    startDate: new Date(date?.start).toISOString(),
                    endDate: new Date(date?.end).toISOString(),
                  })
                );
              }
            } else {
              dispatch(
                getAllHisobotByDateRangeManager({
                  startDate: new Date(date?.start).toISOString(),
                  endDate: new Date(date?.end).toISOString(),
                })
              );
            }
          } else {
            //   dispatch(getAllIncomes());
            //   dispatch(getAllOutcomes());
          }
        }}
      />
      <div className='tableContent'>
        {hisobot && (
          <ProTable
            isCreatable={false}
            tableName='Шартнома'
            searchIndexes={searchIndexes}
            //   viewButtonUrl='/reportPayment'
            //   createSubmitHandler={(reqBody) =>
            //     dispatch(createReport({ ...reqBody, filialId: +reqBody?.filialId }))
            //   }
            //   editSubmitHandler={(reqBody) => {
            //     dispatch(updateReport({ ...reqBody }));
            //   }}
            //   deleteSubmitHandler={(id) => dispatch(deleteReport({ id }))}
            columns={columns}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
            tableData={hisobot}
          />
        )}
      </div>
    </div>
  );
};

export default HisobotPage;
