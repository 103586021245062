import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Avatar } from "@nextui-org/react";

export default function ProfileButton({ user }) {
  const loc = useLocation();
  const navigation = useNavigate();

  return (
    <button
      className={
        loc.pathname.startsWith("/profile") &&
        !loc.pathname.startsWith("/profilePD")
          ? "group bg-blue-600 dark:bg-blue-600  flex flex-row items-center justify-between gap-3 p-2 transition duration-500 ease-in-out hover:drop-shadow-[0px_0px_15px_rgba(0,119,118,0.3)] box-content rounded-full"
          : " group bg-white dark:bg-zinc-800 dark:hover:bg-forestydark-400 flex flex-row items-center justify-between gap-3 p-2 transition duration-500 ease-in-out hover:drop-shadow-[0px_0px_15px_rgba(0,119,118,0.3)] box-content rounded-full"
      }
      onClick={() => {
        if (!(loc.pathname === "/profile")) {
          navigation("/profile");
        }
      }}
    >
      <div className='flex flex-col ml-4 items-end justify-center leading-4'>
        <span
          className={
            loc.pathname.startsWith("/profile") &&
            !loc.pathname.startsWith("/profilePD")
              ? "text-[12px] font-raleway font-bold text-white dark:group-hover:text-foresty-white transition duration-500 ease-in-out"
              : "text-[12px] font-raleway font-bold text-zinc-700 dark:text-white dark:group-hover:text-foresty-white transition duration-500 ease-in-out"
          }
        >
          {`${user?.surname} ${user?.name}`}
        </span>
        <span
          className={
            loc.pathname.startsWith("/profile") &&
            !loc.pathname.startsWith("/profilePD")
              ? "text-[12px] font-raleway font-extrabold text-white transition duration-500 ease-in-out"
              : "text-[12px] font-raleway font-extrabold dark:text-[#3dd292] text-green-500 transition duration-500 ease-in-out"
          }
        >
          {user?.roles}
        </span>
      </div>
      <Avatar
        isBordered
        size='sm'
        // src={getProfileAvatar(user?.profilePhoto)}
        color='primary'
        className='cursor-pointer'
      />
    </button>
  );
}
