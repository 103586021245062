import * as Yup from "yup";

export const fields = [
  {
    name: "name",
    type: "text",
    label: "Номи",
    placeholder: "Номини киритинг...",
    isRequired: true,
  },
  {
    name: "description",
    type: "text",
    label: "Тавсифи",
    placeholder: "Тавсифини киритинг...",
    isRequired: true,
  },
  {
    name: "filialId",
    type: "customSelect",
    label: "Филиал",
    placeholder: "Филиални танланг...",
    isRequired: true,
  },
];

export const emptyValues = {
  name: "",
  description: "",
  filialId: "",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Бўш бўлмаслиги керак!"),
  description: Yup.string().required("Бўш бўлмаслиги керак!"),
  filialId: Yup.mixed().required("Бўш бўлмаслиги керак!"),
});

export const columns = [
  { name: "ID", uid: "id", sortable: true },
  { name: "Номи", uid: "name", sortable: true },
  { name: "Тавсифи", uid: "description", sortable: true },
  // { name: "Shartnoma raqami", uid: "contractNumber", sortable: true },
  // { name: "To'lov qiymati", uid: "fullAmount", sortable: true },
  // {
  //   name: "Boshlang'ich to'lov qiymati",
  //   uid: "initialPayment",
  //   sortable: true,
  // },
  { name: "Санаси", uid: "createdAt" },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "fullName",
  "storeNumber",
  "contractNumber",
  // "fullAmount",
  // "initialPayment",
  "createdAt",
  "actions",
];

export const searchIndexes = ["name", "description"];
