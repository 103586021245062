import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../UsersPage/usersSlice";
import ProTable from "../../components/ProTable";
import {
  Avatar,
  Badge,
  Button,
  Input,
  ScrollShadow,
  Textarea,
} from "@nextui-org/react";
import { SentIcon } from "hugeicons-react";
import { columns, INITIAL_VISIBLE_COLUMNS, searchIndexes } from "./sendData";
import { createNotification } from "./notificationsSlice";

const SendNotificationsPage = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const { loading } = useSelector((state) => state.notifications);

  // const [group, setGroup] = React.useState(new Set([]));
  const [title, setTitle] = React.useState("");
  const [message, setMessage] = React.useState("");

  // const [selected, setSelected] = useState(TABS.GROUPS);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  // useEffect(() => {
  //   dispatch(resetUsers());
  //   setSelectedUsers([]);

  //   switch (selected) {
  //     case TABS.EMPLOYEES:
  //       dispatch(getEmployees());
  //       break;
  //     case TABS.TEACHERS:
  //       dispatch(getTeachers());
  //       break;
  //     case TABS.STUDENTS:
  //       dispatch(getStudents());
  //       break;
  //   }
  // }, [selected]);

  return (
    <div className='flex flex-col w-full'>
      <div className='grid grid-cols-5 gap-5 px-5'>
        <div className='col-span-3 flex flex-col h-fit gap-5 items-center bg-white dark:bg-neutral-800/50 p-3 px-5 rounded-3xl'>
          {users && (
            <ProTable
              isSelection
              isCreatable={false}
              handleSelectionChange={setSelectedUsers}
              searchIndexes={searchIndexes}
              tableData={users}
              columns={columns}
              initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
              //   editButton={null}
              //   deleteButton={null}
            />
          )}
        </div>
        <div className='flex flex-col col-span-2 bg-white dark:bg-neutral-800/50 p-3 rounded-3xl'>
          <div className='flex flex-col gap-3 w-full text-black dark:text-white'>
            <Input
              value={title}
              onValueChange={setTitle}
              variant='bordered'
              label='Сарлавҳа'
              placeholder='Сарлавҳани киритинг...'
              className='w-full'
            />{" "}
            <Textarea
              value={message}
              onValueChange={setMessage}
              variant='bordered'
              label='Матни'
              placeholder='Матнини киритинг...'
              className='w-full'
              minRows={3}
              maxRows={10}
              classNames={
                {
                  // input: "resize-y min-h-[150px]",
                }
              }
            />
          </div>

          <div className='mt-4 ml-5'>
            <Badge content={selectedUsers.length} color='primary'>
              <div className='text-[16px] font-bold w-fit mr-3 dark:text-white text-black'>
                {"Қабул қилувчилар"}
              </div>
            </Badge>
          </div>
          <div className='flex flex-col gap-2 mt-2 bg-neutral-200 dark:bg-neutral-800 p-2 rounded-3xl'>
            {" "}
            <ScrollShadow
              size={40}
              className='overflow-scroll scrollbar-hide flex flex-col w-full gap-3 max-h-[300px]'
            >
              {selectedUsers.length > 0 ? (
                users &&
                selectedUsers.map((u) => {
                  let user = users.find((el) => el.id == u);
                  return (
                    <div className='flex flex-row justify-between gap-3 items-center bg-white dark:bg-neutral-700/50 w-full rounded-2xl p-3'>
                      <div className='flex flex-row items-center gap-3 w-full'>
                        <Avatar
                          //   src={getProfileAvatar(user?.profilePhoto)}
                          className='w-[40px] h-[40px]'
                        />
                        <div className='flex flex-col'>
                          <span className='text-[13px] truncate w-[150px] dark:text-white text-black font-semibold'>{`${user?.surname} ${user?.name}`}</span>
                          {/* <span className='font-normal text-[13px] text-orange-500'>{`${getPhoneNumberPattern(
                            user?.user?.phoneNumber
                          )}`}</span> */}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className='text-[14px] flex justify-center items-center'>
                  {"No data."}
                </span>
              )}
            </ScrollShadow>
          </div>
          <div className='flex flex-row justify-end mt-6'>
            <Button
              isLoading={loading}
              onClick={() =>
                dispatch(
                  createNotification({
                    data: {
                      title: title,
                      message: message,
                      userIDs: selectedUsers.map((el) => +el),
                    },
                  })
                )
              }
              disabled={
                message === "" || title === "" || selectedUsers.length === 0
              }
              color={
                message === "" || title === "" || selectedUsers.length === 0
                  ? "default"
                  : "primary"
              }
              endContent={<SentIcon className='w-[20px]' />}
            >
              {"Жўнатиш"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendNotificationsPage;
