import * as Yup from "yup";

export const fields = [
  {
    name: "title",
    type: "text",
    label: "Сарлавҳа",
    placeholder: "Сарлавҳани киритинг...",
    isRequired: true,
  },
  {
    name: "message",
    type: "text",
    label: "Матни",
    placeholder: "Матнини киритинг...",
    isRequired: true,
  },
];

export const emptyValues = {
  title: "",
  message: "",
};

export const validationSchema = Yup.object().shape({
  title: Yup.string().required("Бўш бўлмаслиги керак!"),
  message: Yup.string().required("Бўш бўлмаслиги керак!"),
});

export const columns = [
  { name: "ID", uid: "rowOrder", sortable: true },
  // { name: "Avatar", uid: "profilePhoto", sortable: true },
  { name: "Сарлавҳа", uid: "notification.title", sortable: true },
  { name: "Матни", uid: "notification.message", sortable: true },

  { name: "Санаси", uid: "notification.createdAt", sortable: true },

  { name: "", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "notification.title",
  "notification.message",
  "notification.createdAt",
  "actions",
];

export const searchIndexes = ["title", "message"];
