import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeBC } from "../DashboardPage/dashboardSlice";
import { Formik } from "formik";
import {
  passwordFields,
  passwordValidationSchema,
  userFields,
  userValidationSchema,
} from "./data";
import { Button, Input } from "@nextui-org/react";
import { editProfileUser } from "../Login/authSlice";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(changeBC([{ name: "Шахсий кабинет", link: "/profile" }]));
  }, []);
  return (
    user && (
      <div className='grid grid-cols-2 w-full px-5 gap-5'>
        <div className='flex flex-col tableContent'>
          <Formik
            // validate
            // validateOnChange={true}
            // validateOnBlur={t}
            // validateOnBlur={true}
            // validateOnChange={true}
            enableReinitialize={true}
            initialValues={{
              name: user?.name,
              surname: user?.surname,
              username: user?.username,
              phoneNumber: user?.phoneNumber,
              // confirm: "string",
            }}
            validationSchema={userValidationSchema}
            onSubmit={(values) => {
              dispatch(
                editProfileUser({
                  name: values?.name,
                  surname: values?.surname,
                  username: values?.username,
                  phoneNumber: values?.phoneNumber,
                  // confirm: "string",
                })
              );
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
              isValid,
              dirty,
            }) => (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();

                  handleSubmit(values);
                }}
                className='flex flex-col gap-5 w-full font-madefor'
              >
                {userFields?.map((field, ind) => (
                  <Input
                    key={ind}
                    // className='text-black dark:text-white'
                    // isInvalid={
                    //   !touched[field.name] && !errors[field.name]
                    // }
                    isInvalid={
                      touched[field.name] && Boolean(errors[field.name])
                    }
                    errorMessage={errors[field.name]}
                    isRequired={field?.isRequired}
                    style={{ fontWeight: 500 }}
                    label={field.label}
                    // labelPlacement='outside'
                    placeholder={field.placeholder}
                    name={field.name}
                    value={values[field.name]}
                    type={field.type}
                    onChange={handleChange}
                  />
                ))}
                <Button
                  //   isLoading={isLoading}
                  disabled={!isValid || !dirty}
                  type='submit'
                  color={!isValid || !dirty ? "default" : "primary"}
                >
                  {"Сақлаш"}
                </Button>
              </form>
            )}
          </Formik>
        </div>

        <div className='flex flex-col tableContent h-fit'>
          <Formik
            // validate
            // validateOnChange={true}
            // validateOnBlur={t}
            // validateOnBlur={true}
            // validateOnChange={true}
            enableReinitialize
            initialValues={user}
            validationSchema={passwordValidationSchema}
            onSubmit={(values) => {
              dispatch(
                editProfileUser({
                  name: values?.name,
                  surname: values?.surname,
                  username: values?.username,
                  phoneNumber: values?.phoneNumber,
                  oldPassword: values?.oldPassword,
                  newPassword: values?.newPassword,
                  confirm: "string",
                })
              );
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
              isValid,
              dirty,
            }) => (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();

                  handleSubmit(values);
                }}
                className='flex flex-col gap-5 w-full font-madefor'
              >
                {passwordFields?.map((field, ind) => (
                  <Input
                    key={ind}
                    // className='text-black dark:text-white'
                    // isInvalid={
                    //   !touched[field.name] && !errors[field.name]
                    // }
                    isInvalid={
                      touched[field.name] && Boolean(errors[field.name])
                    }
                    errorMessage={errors[field.name]}
                    isRequired={field?.isRequired}
                    style={{ fontWeight: 500 }}
                    label={field.label}
                    // labelPlacement='outside'
                    placeholder={field.placeholder}
                    name={field.name}
                    value={values[field.name]}
                    type={field.type}
                    onChange={handleChange}
                  />
                ))}
                <Button
                  //   isLoading={isLoading}
                  disabled={!isValid || !dirty}
                  type='submit'
                  color={!isValid || !dirty ? "default" : "primary"}
                >
                  {"Сақлаш"}
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    )
  );
};

export default ProfilePage;
