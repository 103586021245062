import { Button } from "@nextui-org/react";
import React, { useState } from "react";
import { DownloadFile } from "../config/downloadFile";
import { Xsl02Icon } from "hugeicons-react";
import { BASE_URL } from "../config/Api";

const ExportButton = ({ exportData }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  return (
    <Button
      disabled={downloadLoading}
      isLoading={downloadLoading}
      onClick={async () => {
        try {
          setDownloadLoading(true);
          await DownloadFile(
            `${BASE_URL}${exportData?.link}`,
            exportData?.fileName
          );
          setDownloadLoading(false);
        } catch {}
      }}
      variant='solid'
      color={downloadLoading ? "default" : "success"}
      className='text-white dark:bg-green-700'
    >
      <Xsl02Icon />
      {"Export XLSX"}
    </Button>
  );
};

export default ExportButton;
