import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import Api from "../../config/Api";
import { toast } from "react-toastify";

export const getAllReport = createAsyncThunk(
  "report/getAll",
  async (body = {}) => {
    const response = await Api.get("/report");
    return response.data;
  }
);

export const getAllReportByBranchId = createAsyncThunk(
  "report/getAllByBranchId",
  async (body = {}) => {
    const response = await Api.get(`/report/filial/${body}`);
    return response.data;
  }
);

export const getAllReportBranch = createAsyncThunk(
  "report/getAllBranch",
  async (body = {}) => {
    const response = await Api.get(`/report/filial`);
    return response.data;
  }
);

export const getReportById = createAsyncThunk(
  "report/getById",
  async (body = {}) => {
    const response = await Api.get(`/report/${body?.id}`);
    return response.data;
  }
);

export const getReportPaymentTotal = createAsyncThunk(
  "report/getPaymentTotal",
  async (body = {}) => {
    const response = await Api.get(`/reportPayment/total/${body?.id}`);
    return response.data;
  }
);

export const getReportPaymentRelease = createAsyncThunk(
  "report/getPaymentRelease",
  async (body = {}) => {
    const response = await Api.get(`/reportPayment/release/${body?.id}`);
    return response.data;
  }
);

export const getReportPaymentsById = createAsyncThunk(
  "report/getReportPaymentsById",
  async (body = {}) => {
    const response = await Api.get(`/reportPayment/${body?.id}/payments`);
    return response.data;
  }
);

export const createReport = createAsyncThunk(
  "report/create",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post("/report", body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadImage = createAsyncThunk(
  "report/uploadImage",
  async (body, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("file", body?.file);
    try {
      const response = await Api.post("/fayl/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      let reqBody = { fileEntityId: response?.data?.id, ...body?.reportData };
      const res = await Api.put(`/report/${reqBody?.id}`, reqBody);

      body?.removeSelection();
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReport = createAsyncThunk(
  "report/update",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/report/${body?.id}`, body);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteReport = createAsyncThunk(
  "report/delete",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/report/${body?.id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createPayment = createAsyncThunk(
  "report/createPayment",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `/reportPayment/${body?.reportId}/add-payment?newPayment=${body?.data}`
      );
      // body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePayment = createAsyncThunk(
  "report/updatePayment",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(
        `/reportPayment/${body?.reportId}/updatePayment/${body?.id}`,
        body?.data
      );
      // body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePayment = createAsyncThunk(
  "report/deletePayment",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/reportPayment/${+body?.paymentId}`);
      // body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState: {
    reports: null,
    reportPayments: null,
    report: null,
    loading: false,
    uploadLoading: false,
    error: null,

    total: null,
    release: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    clearReport: (state) => {
      state.report = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET report ------------------/////
      .addCase(getAllReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllReport.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload?.content;
      })
      .addCase(getAllReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET report ------------------/////
      .addCase(getAllReportBranch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllReportBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload?.content;
      })
      .addCase(getAllReportBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET report ------------------/////
      .addCase(getAllReportByBranchId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllReportByBranchId.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload;
      })
      .addCase(getAllReportByBranchId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET report ------------------/////
      .addCase(getReportById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportById.fulfilled, (state, action) => {
        state.loading = false;
        state.report = action.payload;
      })
      .addCase(getReportById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET report ------------------/////
      .addCase(getReportPaymentTotal.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportPaymentTotal.fulfilled, (state, action) => {
        state.loading = false;
        state.total = action.payload;
      })
      .addCase(getReportPaymentTotal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET report ------------------/////
      .addCase(getReportPaymentRelease.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportPaymentRelease.fulfilled, (state, action) => {
        state.loading = false;
        state.release = action.payload;
      })
      .addCase(getReportPaymentRelease.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET report ------------------/////
      .addCase(getReportPaymentsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportPaymentsById.fulfilled, (state, action) => {
        state.loading = false;
        state.reportPayments = action.payload;
      })
      .addCase(getReportPaymentsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ CREATE report ------------------/////
      .addCase(createReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(createReport.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.reports = [payload?.data, ...state.reports];
        toast.success(payload?.message);
      })
      .addCase(createReport.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ delete report ------------------/////
      .addCase(deleteReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteReport.fulfilled, (state, { payload }) => {
        state.loading = false;

        const ctgIndex = findIndex(state.reports, {
          id: payload?.id,
        });
        state.reports.splice(ctgIndex, 1);
        toast.success(payload?.message);
      })
      .addCase(deleteReport.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ UPDATE report ------------------/////
      .addCase(updateReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateReport.fulfilled, (state, { payload }) => {
        state.loading = false;

        const ctgIndex = findIndex(state.reports, {
          id: payload?.id,
        });

        state.reports[ctgIndex] = payload;
        toast.success(payload?.message);
      })

      ///------------ UPDATE report ------------------/////
      .addCase(uploadImage.pending, (state) => {
        state.uploadLoading = true;
      })
      .addCase(uploadImage.fulfilled, (state, { payload }) => {
        state.uploadLoading = false;

        state.report = payload;
        toast.success(payload?.message);
      })
      .addCase(uploadImage.rejected, (state, { payload }) => {
        state.uploadLoading = false;
        state.error = payload;
        toast.error(payload?.message);
      })

      ///------------ CREATE store payment ------------------/////
      .addCase(createPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPayment.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.reportPayments = [...state.reportPayments, payload];
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE store payment ------------------/////
      .addCase(updatePayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePayment.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.reportPayments, { id: payload?.id });
        state.reportPayments[ctgIndex] = payload;
      })
      .addCase(updatePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ delete payment ------------------/////
      .addCase(deletePayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePayment.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.reportPayments, { id: payload?.id });
        state.reportPayments.splice(ctgIndex, 1);
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError, clearReport } = reportSlice.actions;

export default reportSlice.reducer;
