import * as Yup from "yup";

export const fields = [
  {
    name: "name",
    type: "text",
    label: "Номи",
    placeholder: "Номини киритинг...",
    isRequired: true,
  },
  {
    name: "number",
    type: "number",
    label: "Рақами",
    placeholder: "Рақамини киритинг...",
    isRequired: true,
  },
  {
    name: "price",
    type: "number",
    label: "Нархи",
    placeholder: "Нархини киритинг...",
    isRequired: true,
  },
  {
    name: "square_meters",
    type: "number",
    label: "Квадрат метри",
    placeholder: "Квадрат метрини киритинг...",
    isRequired: true,
  },
  {
    name: "floor",
    type: "number",
    label: "Қават",
    placeholder: "Қаватни киритинг...",
  },

  {
    name: "block",
    type: "text",
    label: "Блок",
    placeholder: "Блокни киритинг...",
  },
  {
    name: "filialId",
    type: "customSelect",
    label: "Филиал",
    placeholder: "Филиални танланг...",
    isRequired: true,
  },
];

export const emptyValues = {
  name: "",
  number: "",
  square_meters: "",
  price: "",
  floor: 0,
  block: "",
  filialId: "",
  // currentSalary: 0,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Бўш бўлмаслиги керак!"),
  number: Yup.number().required("Бўш бўлмаслиги керак!"),
  square_meters: Yup.number().required("Бўш бўлмаслиги керак!"),
  price: Yup.number().required("Бўш бўлмаслиги керак!"),
  floor: Yup.number().required("Бўш бўлмаслиги керак!"),
  block: Yup.string().required("Бўш бўлмаслиги керак!"),
  filialId: Yup.mixed().required("Бўш бўлмаслиги керак!"),

  // currentSalary: Yup.string()
  //   .matches(/^\d+$/, "Iltimos raqam kiriting!")
  //   .required("Joriy maosh bo'sh bo'lmasligi kerak!"),
});

export const columns = [
  { name: "ID", uid: "id", sortable: true },

  { name: "Номи", uid: "name" },
  { name: "Рақами", uid: "number", sortable: true },
  { name: "Квадрат метри", uid: "square_meters", sortable: true },
  { name: "Нархи", uid: "price", sortable: true },
  { name: "Қават", uid: "floor", sortable: true },
  { name: "Блок", uid: "block", sortable: true },

  { name: "Санаси", uid: "createdAt" },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "name",
  "number",
  "square_meters",
  "price",
  "floor",
  "block",

  "createdAt",
  "actions",
];

export const searchIndexes = [
  "name",
  "number",
  "square_meters",
  "price",
  "floor",
  "block",
];
