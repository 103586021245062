import * as Yup from "yup";

export const salaryFields = [
  {
    name: "newSalary",
    type: "number",
    label: "Янги маош",
    placeholder: "Янги маошни киритинг...",
  },
];

export const salaryEmptyValues = {
  newSalary: 0,
};

export const salaryValidationSchema = Yup.object().shape({
  newSalary: Yup.number().required("Бўш бўлмаслиги керак!"),
});

export const Roles = [
  { id: "ADMIN", name: "Admin" },
  { id: "MANAGER", name: "Manager" },
  { id: "USER", name: "User" },
];

export const fields = [
  {
    name: "name",
    type: "text",
    label: "Исми",
    placeholder: "Исмини киритинг...",
    isRequired: true,
  },
  {
    name: "surname",
    type: "text",
    label: "Фамилия",
    placeholder: "Фамилияни киритинг...",
    isRequired: true,
  },
  {
    name: "phoneNumber",
    type: "text",
    label: "Тел. рақами",
    placeholder: "Тел. рақамини киритинг...",
  },
  {
    name: "roles",
    type: "customSelect",
    label: "Рол",
    placeholder: "Ролни танланг...",
  },

  {
    name: "username",
    type: "text",
    label: "Username",
    placeholder: "Usernameni kiriting...",
  },

  {
    name: "password",
    type: "text",
    label: "Parol",
    placeholder: "Parolni kiriting...",
  },
  // {
  //   name: "currentSalary",
  //   isRequired: true,
  //   type: "text",
  //   label: "Joriy maosh",
  //   placeholder: "Joriy maoshni kiriting...",
  // },
];

export const emptyValues = {
  name: "",
  surname: "",
  phoneNumber: "",
  username: "",
  password: "",
  roles: "",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Бўш бўлмаслиги керак!"),
  surname: Yup.string().required("Бўш бўлмаслиги керак!"),
  phoneNumber: Yup.string().required("Бўш бўлмаслиги керак!"),
  username: Yup.string().required("Бўш бўлмаслиги керак!"),
  password: Yup.string().required("Бўш бўлмаслиги керак!"),
  roles: Yup.string().required("Бўш бўлмаслиги керак!"),
  // currentSalary: Yup.string()
  //   .matches(/^\d+$/, "Iltimos raqam kiriting!")
  //   .required("Joriy maosh bo'sh bo'lmasligi kerak!"),
});

export const columns = [
  { name: "ID", uid: "id", sortable: true },
  // { name: "Avatar", uid: "profilePhoto", sortable: true },
  { name: "Фамилияси", uid: "surname", sortable: true },
  { name: "Исми", uid: "name", sortable: true },
  { name: "Тел. рақами", uid: "phoneNumber", sortable: true },
  // { name: "MAOSH", uid: "currentSalary", sortable: true },
  // { name: "MAOSH BERILISH VAQTI", uid: "salaryDate", sortable: true },

  // { name: "SANASI", uid: "createdAt" },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "name",
  "profilePhoto",
  "surname",
  "phoneNumber",
  // "currentSalary",
  "jobDescription",
  "salaryDate",
  // "createdAt",
  "actions",
];

export const searchIndexes = [
  "name",
  "surname",
  "phoneNumber",
  "currentSalary",
  "jobDescription",
  "salaryDate",
];

export const assignBranchFields = [
  {
    name: "filialId",
    type: "customSelect",
    label: "Filial",
    placeholder: "Filialni tanlang...",
    isRequired: true,
  },
];

export const assignBranchEmptyValues = {
  filialId: "",
};

export const assignBranchValidationSchema = Yup.object().shape({
  filialId: Yup.mixed().required("Ismi bo'sh bo'lmasligi kerak!"),
});
