import React, { useEffect } from "react";
import { getActivityDate } from "../../utils/date";

const NotificationCard = ({ ntf }) => {
  return (
    <div className='flex flex-row justify-between items-center w-full p-3 px-5 bg-neutral-50 dark:bg-zinc-800 rounded-lg relative'>
      {ntf?.status === "UNREAD" && (
        <span className='w-[8px] h-[8px] rounded-full bg-green-500 absolute top-[6px] left-[6px]'></span>
      )}
      <div className='flex flex-col'>
        <span className='text-black dark:text-white font-semibold w-[230px] break-words'>
          {ntf?.notification?.title}
        </span>
        <span className='text-zinc-800 dark:text-zinc-300 w-[230px] break-words'>
          {ntf?.notification?.message}
        </span>
      </div>
      <span className='text-[13px] text-zinc-800 dark:text-zinc-400'>
        {getActivityDate(new Date(ntf?.notification?.createdAt * 1000))}
      </span>
    </div>
  );
};

export default NotificationCard;
