import React, { useEffect } from "react";
import ProTable from "../../../components/ProTable";
import { useDispatch, useSelector } from "react-redux";
import {
  clearReport,
  createPayment,
  deletePayment,
  getAllReport,
  getReportById,
  getReportPaymentRelease,
  getReportPaymentsById,
  getReportPaymentTotal,
  updatePayment,
} from "../reportSlice";
import {
  columns,
  emptyValues,
  fields,
  INITIAL_VISIBLE_COLUMNS,
  searchIndexes,
  validationSchema,
} from "./data";
import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbItem, Breadcrumbs, Divider } from "@nextui-org/react";
import { BanknotesIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { getMoneyPattern } from "../../../utils/regex";
import { changeBC } from "../../DashboardPage/dashboardSlice";

const ReportPaymentsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  // const { branches } = useSelector((state) => state.branches);
  const { reports, report, reportPayments, total, release } = useSelector(
    (state) => state.report
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      changeBC([
        { name: "Шартномалар", link: "/agreements" },
        { name: report?.name, link: "/" },
      ])
    );
  }, [report]);
  useEffect(() => {
    dispatch(getReportById({ id }));
    dispatch(getReportPaymentTotal({ id }));
    dispatch(getReportPaymentRelease({ id }));
    dispatch(getAllReport());
    dispatch(getReportPaymentsById({ id: id }));

    return () => {
      dispatch(clearReport());
    };
  }, []);
  return (
    report && (
      <div className='flex flex-col w-full px-3 h-full'>
        <div className='flex flex-col p-3 bg-neutral rounded-xl gap-5'>
          <div className='grid grid-cols-5 gap-5'>
            <div className='flex flex-col w-full col-span-2 gap-5'>
              <div className='flex flex-col w-full bg-white dark:bg-zinc-800 shadow-2xl p-5 rounded-xl'>
                <span className='font-bold text-[16px] text-black dark:text-white'>
                  {report?.name}
                </span>
                <Divider className='my-2' />
                <div className='grid grid-cols-2'>
                  <span className='text-[14px] text-neutral-600 dark:text-neutral-400 font-semibold'>
                    {"Номи"}
                  </span>
                  <span className='text-[14px] text-black dark:text-white font-semibold'>
                    {report?.name}
                  </span>
                </div>
                <Divider className='my-2' />
                <div className='grid grid-cols-2'>
                  <span className='text-[14px] text-neutral-600 dark:text-neutral-400 font-semibold'>
                    {"Рақами"}
                  </span>
                  <span className='text-[14px] text-black dark:text-white font-semibold'>
                    {report?.number}
                  </span>
                </div>
                <Divider className='my-2' />
                <div className='grid grid-cols-2'>
                  <span className='text-[14px] text-neutral-600 dark:text-neutral-400 font-semibold'>
                    {"Квадрат метри"}
                  </span>
                  <span className='text-[14px] text-black dark:text-white font-semibold'>
                    {`${report?.square_meters} m²`}
                  </span>
                </div>
                <Divider className='my-2' />
                <div className='grid grid-cols-2'>
                  <span className='text-[14px] text-neutral-600 dark:text-neutral-400 font-semibold'>
                    {"Нархи"}
                  </span>
                  <span className='text-[14px] text-black dark:text-white font-semibold'>
                    {`${getMoneyPattern(report?.fullAmount, ",")} UZS`}
                  </span>
                </div>
                <Divider className='my-2' />
                <div className='grid grid-cols-2'>
                  <span className='text-[14px] text-neutral-600 dark:text-neutral-400 font-semibold'>
                    {"Квадрат метри нархи"}
                  </span>
                  <span className='text-[14px] text-black dark:text-white font-semibold'>
                    {`${getMoneyPattern(report?.price, ",")} UZS`}
                  </span>
                </div>
                <Divider className='my-2' />
                <div className='grid grid-cols-2'>
                  <span className='text-[14px] text-neutral-600 dark:text-neutral-400 font-semibold'>
                    {"Қават"}
                  </span>
                  <span className='text-[14px] text-black dark:text-white font-semibold'>
                    {`${report?.floor}`}
                  </span>
                </div>
                <Divider className='my-2' />
                <div className='grid grid-cols-2'>
                  <span className='text-[14px] text-neutral-600 dark:text-neutral-400 font-semibold'>
                    {"Блок"}
                  </span>
                  <span className='text-[14px] text-black dark:text-white font-semibold'>
                    {`${report?.block}`}
                  </span>
                </div>
              </div>
              <div className='grid grid-cols-2 w-full gap-3'>
                <div className='flex flex-col gap-1 w-full bg-white dark:bg-zinc-800 shadow-2xl rounded-2xl p-5 h-fit'>
                  <CurrencyDollarIcon className='w-[28px] text-green-500 mb-1' />
                  <span className='text-[14px] text-neutral-500 font-semibold'>
                    {"Тўланган сумма"}
                  </span>
                  <span className='text-[16px] text-black dark:text-white font-bold mb-1'>
                    {`${getMoneyPattern(total, ",")} UZS`}
                  </span>
                </div>
                <div className='flex flex-col gap-1 w-full bg-white dark:bg-zinc-800 shadow-2xl rounded-2xl p-5 h-fit'>
                  <BanknotesIcon className='w-[28px] text-pink-500 mb-1' />
                  <span className='text-[14px] text-neutral-500 font-semibold'>
                    {"Қарздорлик"}
                  </span>
                  <span className='text-[16px] text-black dark:text-white font-bold mb-1'>
                    {`${getMoneyPattern(release, ",")} UZS`}
                  </span>
                </div>
              </div>
            </div>
            <div className='flex flex-col w-full col-span-3 bg-white dark:bg-zinc-800/50 px-5 py-2 rounded-xl'>
              <span className='font-bold text-[18px] text-primary'>
                {"Тўловлар"}
              </span>
              <div className='flex flex-col w-full items-center gap-5'>
                {reports && reportPayments && (
                  <ProTable
                    tableName='Тўлов'
                    searchIndexes={searchIndexes}
                    // viewButtonUrl='/report/payments'
                    createSubmitHandler={(reqBody) =>
                      dispatch(
                        createPayment({
                          data: reqBody?.newPayment,
                          reportId: id,
                        })
                      )
                    }
                    editSubmitHandler={(reqBody) => {
                      console.log(reqBody);
                      dispatch(updatePayment({ ...reqBody, reportId: id }));
                    }}
                    deleteSubmitHandler={(id) =>
                      dispatch(deletePayment({ id }))
                    }
                    columns={columns}
                    initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
                    tableData={reportPayments}
                    createData={{
                      fields,
                      initialValues: emptyValues,
                      validationSchema,
                    }}
                    editData={{
                      fields,
                      initialValues: (data) => ({
                        // reportId: +data?.filial?.id,
                        ...data,
                      }),
                      validationSchema,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ReportPaymentsPage;
