import React, { useEffect } from "react";
import ProTable from "../../components/ProTable";
import { useDispatch, useSelector } from "react-redux";
import {
  fields,
  emptyValues,
  validationSchema,
  columns,
  INITIAL_VISIBLE_COLUMNS,
  searchIndexes,
  Roles,
  assignBranchFields,
  assignBranchValidationSchema,
  assignBranchEmptyValues,
} from "./data";
import {
  assignUserToBranch,
  createUser,
  deleteUser,
  getAllUsers,
  updateUser,
} from "./usersSlice";
import { getAllBranches } from "../BranchesPage/branchesSlice";
import CreateModal from "../../components/Modals/CreateModal";
import { changeBC } from "../DashboardPage/dashboardSlice";
import { Button } from "rsuite";
import { PoliceBadgeIcon } from "hugeicons-react";

const UsersPage = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const { branches } = useSelector((state) => state.branches);

  useEffect(() => {
    dispatch(changeBC([{ name: "Фойдаланувчилар", link: "/users" }]));

    dispatch(getAllUsers());
    dispatch(getAllBranches());
  }, []);

  return (
    <div className='flex flex-col w-full px-5 gap-8 h-full'>
      <div className='tableContent'>
        {users && branches && (
          <ProTable
            searchIndexes={searchIndexes}
            // viewButtonUrl='/users'
            createSubmitHandler={(reqBody) =>
              dispatch(
                createUser({
                  // roles: reqBody?.role,
                  currentSalary: +reqBody?.currentSalary,
                  ...reqBody,
                })
              )
            }
            editSubmitHandler={(reqBody) => {
              console.log(reqBody);
              dispatch(
                updateUser({
                  roles: reqBody?.role,
                  ...reqBody,
                })
              );
            }}
            deleteSubmitHandler={(id) => dispatch(deleteUser({ id }))}
            columns={columns}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
            tableData={users}
            createData={{
              fields: fields.map((el) => {
                if (el.name === "roles") {
                  el.selectData = Roles.map((el) => ({
                    name: el?.name,
                    ...el,
                  }));
                  return el;
                }

                return el;
              }),
              initialValues: emptyValues,
              validationSchema,
            }}
            editData={{
              fields,
              initialValues: (data) => ({ ...data }),
              validationSchema,
            }}
            extraButton={(data) => (
              <CreateModal
                button={
                  <button className='text-lg text-green-500 text-default-400 cursor-pointer active:opacity-50'>
                    <PoliceBadgeIcon className='w-[20px]' />
                  </button>
                }
                // ctgs={categories}
                handleSubmit={(reqBody) =>
                  dispatch(
                    assignUserToBranch({
                      branchId: reqBody?.filialId,
                      userId: data?.id,
                    })
                  )
                }
                // btnText={`Filialga bog'lash qo'shish`}
                fields={assignBranchFields.map((el) => {
                  if (el.name === "filialId") {
                    el.selectData = branches.map((el) => ({
                      name: el?.name,
                      ...el,
                    }));
                    return el;
                  }

                  return el;
                })}
                validationSchema={assignBranchValidationSchema}
                initialValues={assignBranchEmptyValues}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default UsersPage;
