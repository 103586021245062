import {
  Button,
  Card,
  Chip,
  Divider,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import React, { useState } from "react";
import EditModal from "../Modals/EditModal";
import { uploadImage } from "../../pages/PDPage/pdSlice";
import { uploadImage as uploadImageCD } from "../../pages/PDPage/cdSlice";
import { getStoreImage } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/24/outline";

const StoreCard = ({ store, editModal, deleteModal, link }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const [image, setImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);

  const { uploadLoading } = useSelector((state) => state.profilePD);
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
      setIsImageSelected(true);
    }
  };

  return (
    <>
      <Card
        onPress={() => navigate(`${link}/${store?.id}`)}
        isPressable
        className='flex flex-col hover:no-underline w-full bg-white border-[1px] dark:bg-zinc-800 dark:border-neutral-600 border-neutral-300 rounded-3xl p-2'
      >
        <img
          className={
            store?.fileEntity
              ? "w-full aspect-square object-cover rounded-2xl"
              : "w-full aspect-square object-cover rounded-2xl dark:invert dark:saturate-100"
          }
          src={getStoreImage(store?.fileEntity)}
        />
        <div className='flex flex-col w-full p-2'>
          <div className='flex flex-row justify-between items-center'>
            <span className='text-[14px] hover:no-underline font-bold text-black dark:text-white'>
              {store?.name}
            </span>
            <Button
              size='sm'
              variant='flat'
              color='warning'
              isIconOnly
              onClick={(e) => {
                e.stopPropagation();
                onOpen();
              }}
            >
              <PencilIcon className='w-[20px]' />
            </Button>
            {/* <Chip
              size='sm'
              color='warning'
              variant='bordered'
              className='text-[14px] font-bold'
            >
              {store?.size + "m²"}
            </Chip> */}
          </div>
          {/* <div className='flex flex-row'>
            <span className='text-[14px] font-normal text-black'>
              {store?.categoryStore?.name}
            </span>
          </div> */}
        </div>
      </Card>
      <Modal
        size='4xl'
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement='top-center'
        className='text-black dark:text-white'
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                {store?.name}
              </ModalHeader>
              <ModalBody className='flex flex-row'>
                <div className='flex flex-col items-center w-[300px] gap-5 py-3 px-5'>
                  {/* {label && (
                    <span className='text-[16px] font-semibold font-space mb-[-30px]'>
                      {label}
                    </span>
                  )} */}
                  <input
                    onChange={handleFileChange}
                    type='file'
                    name='avatar'
                    id={store?.id}
                    className={"w-[300px] invisible h-0 overflow-hidden"}
                    accept='image/*'
                  />
                  <label
                    htmlFor={store?.id}
                    // onMouseEnter={() => setIsImgHover(true)}
                    // onMouseLeave={() => setIsImgHover(false)}
                    className='group w-fit'
                  >
                    <div
                      className={
                        "w-[300px] h-[300px] relative rounded-[30px] overflow-hidden"
                      }
                    >
                      <img
                        src={
                          image === null
                            ? getStoreImage(store?.fileEntity)
                            : URL.createObjectURL(image)
                        }
                        className={
                          image || store?.fileEntity
                            ? "w-full h-full object-cover bg-contain bg-no-repeat "
                            : "w-full h-full object-cover bg-contain bg-no-repeat dark:invert dark:saturate-100"
                        }
                      />

                      <div
                        className={
                          // isImgHover
                          // ? "flex justify-center items-end pb-5 w-full h-full absolute rounded-[30px] top-0 left-0 bg-forestydark-400/30"
                          "flex justify-center items-end pb-5 w-full h-full invisible group-hover:visible absolute rounded-[30px] top-0 left-0 bg-forestydark-400/30"
                        }
                      >
                        <span className='text-foresty-white'>Rasm tanlash</span>
                      </div>
                    </div>
                  </label>
                  <div className={"flex flex-row items-center gap-2 w-full"}>
                    <Button
                      className='flex flex-grow'
                      isLoading={uploadLoading}
                      color={!isImageSelected ? "default" : "primary"}
                      onClick={() => {
                        link === "/profilePD/cd"
                          ? dispatch(
                              uploadImageCD({
                                file: image,
                                profileCDData: {
                                  id: store?.id,
                                  name: store?.name,
                                  description: store?.description,
                                  filialId: store?.filial?.id,
                                },
                                removeSelection: () => {
                                  setIsImageSelected(false);
                                  setImage(null);
                                },
                              })
                            )
                          : dispatch(
                              uploadImage({
                                file: image,
                                profilePDData: {
                                  id: store?.id,
                                  name: store?.name,
                                  description: store?.description,
                                  filialId: store?.filial?.id,
                                },
                                removeSelection: () => {
                                  setIsImageSelected(false);
                                  setImage(null);
                                },
                              })
                            );
                      }}
                      disabled={!isImageSelected}
                    >
                      {" Rasmni yuklash"}
                    </Button>
                    {/* {imageData != null && (
                      <DeleteModal
                        button={
                          isCompact ? (
                            <DeleteButton />
                          ) : (
                            <DeleteButtonText>{"O'chirish"}</DeleteButtonText>
                          )
                        }
                        contextText={`Avatarni `}
                        handleSubmit={() =>
                          dispatch(deleteImage({ url: deleteUrl }))
                        }
                      />
                    )} */}
                  </div>
                </div>
                {/* <div className='flex flex-col w-[300px] gap-3'>
                  <img
                    className='w-full h-[300px] object-cover rounded-2xl'
                    src='https://bsmi.uz/wp-content/uploads/2021/12/empty-img.png'
                  />
                  <Button>{"Rasm yuklash"}</Button>
                </div> */}
                <div className='flex flex-col grow items-end'>
                  <div className='flex flex-col w-full p-5 bg-white dark:bg-zinc-900 rounded-xl h-fit'>
                    <div className='flex flex-row justify-between'>
                      <span className='text-[16px] text-black dark:text-white font-semibold'>
                        {"Адтель закуп"}
                      </span>
                    </div>
                    <div className='flex flex-row justify-between mt-5'>
                      <span className='text-[14px] text-neutral-600 dark:text-neutral-400 font-semibold'>
                        {"Номи"}
                      </span>
                      <span className='text-[14px] text-black dark:text-white font-semibold'>
                        {store?.name}
                      </span>
                    </div>
                    <Divider className='my-2' />
                    <div className='flex flex-row justify-between'>
                      <span className='text-[14px] text-neutral-600 dark:text-neutral-400 font-semibold'>
                        {"Тавсифи"}
                      </span>
                      <span className='text-[14px] text-black dark:text-white font-semibold'>
                        {store?.description}
                      </span>
                    </div>

                    <Divider className='my-2' />
                    <div className='flex flex-row justify-between'>
                      <span className='text-[14px] text-neutral-600 dark:text-neutral-400 font-semibold'>
                        {"Филиал"}
                      </span>
                      <span className='text-[14px] text-black dark:text-white font-semibold'>
                        {`${store?.filial?.name}`}
                      </span>
                    </div>
                  </div>

                  <div className='flex flex-row w-full justify-between px-5 h-full items-end'>
                    {editModal(store)}
                    {deleteModal(store)}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                {/* <Button color='danger' variant='light' onPress={onClose}>
                  Close
                </Button>
                <Button color='primary' onPress={onClose}>
                  Action
                </Button> */}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default StoreCard;
