import * as Yup from "yup";

export const fields = [
  {
    name: "name",
    type: "text",
    label: "Номи",
    placeholder: "Номини киритинг...",
    isRequired: true,
  },
  {
    name: "price",
    type: "number",
    label: "Нархи",
    placeholder: "Нархини киритинг...",
    isRequired: true,
  },
  {
    name: "description",
    type: "text",
    label: "Тавсифи",
    placeholder: "Тавсифини киритинг...",
    isRequired: true,
  },
  {
    name: "number",
    type: "number",
    label: "Сони",
    placeholder: "Сонини киритинг...",
  },

  {
    name: "payment",
    type: "number",
    label: "Тўлов",
    placeholder: "Тўловни киритинг...",
  },
  //   {
  //     name: "filialId",
  //     type: "customSelect",
  //     label: "Filial",
  //     placeholder: "Filialni tanlang...",
  //     isRequired: true,
  //   },
];

export const emptyValues = {
  name: "",
  price: "",
  description: "",
  number: "",
  payment: 0,
  //   block: "",
  //   filialId: "",
  // currentSalary: 0,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Бўш бўлмаслиги керак!"),
  price: Yup.number().required("Бўш бўлмаслиги керак!"),
  description: Yup.string().required("Бўш бўлмаслиги керак!"),
  number: Yup.number().required("Бўш бўлмаслиги керак!"),
  payment: Yup.number().required("Бўш бўлмаслиги керак!"),

  // currentSalary: Yup.string()
  //   .matches(/^\d+$/, "Iltimos soni kiriting!")
  //   .required("Joriy maosh bo'sh bo'lmasligi kerak!"),
});

export const columns = [
  { name: "ID", uid: "id", sortable: true },

  { name: "Номи", uid: "name" },
  { name: "Тавсифи", uid: "description" },

  { name: "Сони", uid: "number", sortable: true },
  { name: "Нархи", uid: "price", sortable: true },
  { name: "Умумий нархи", uid: "fullAmount", sortable: true },

  { name: "Тўлов", uid: "payment", sortable: true },

  { name: "Санаси", uid: "createdAt" },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "name",
  "description",
  "number",
  "price",
  "fullAmount",
  "payment",

  "createdAt",
  "actions",
];

export const searchIndexes = [
  "name",
  "description",
  "number",
  "price",
  "fullAmount",

  "payment",
];
