import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNotification,
  getAllNotifications,
  updateNotification,
} from "./notificationsSlice";
import ProTable from "../../components/ProTable";
import {
  columns,
  fields,
  INITIAL_VISIBLE_COLUMNS,
  searchIndexes,
  validationSchema,
} from "./sentData";
import {
  Avatar,
  Button,
  Chip,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollShadow,
} from "@nextui-org/react";
import {
  Tick01Icon,
  Tick04Icon,
  TickDouble04Icon,
  UserCircle02Icon,
} from "hugeicons-react";

const SentNotificationsPage = () => {
  const dispatch = useDispatch();

  const { loading, notifications } = useSelector(
    (state) => state.notifications
  );

  useEffect(() => {
    dispatch(getAllNotifications());
  }, []);

  useEffect(() => {
    console.log(notifications);
  }, [notifications]);
  return (
    <div className='flex flex-col w-full px-5'>
      <div className='tableContent'>
        {" "}
        {notifications && (
          <ProTable
            isCreatable={false}
            searchIndexes={searchIndexes}
            tableData={notifications}
            columns={columns}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
            //   editButton={null}
            //   deleteButton={null}
            editSubmitHandler={(reqBody) => {
              dispatch(updateNotification({ ...reqBody }));
            }}
            deleteSubmitHandler={(id) => dispatch(deleteNotification({ id }))}
            editData={{
              fields,
              initialValues: (data) => ({
                id: data?.notification?.id,
                title: data?.notification?.title,
                message: data?.notification?.message,
                // ...data,
              }),
              validationSchema,
            }}
            extraButton={(data) => (
              <Popover placement='left'>
                <PopoverTrigger>
                  <button
                    // onClick={onOpen}
                    className='text-lg text-green-400 cursor-pointer active:opacity-50'
                  >
                    <UserCircle02Icon className='w-[23px]' />
                  </button>
                </PopoverTrigger>
                <PopoverContent className='rounded-[30px]'>
                  <ScrollShadow
                    size={40}
                    className='overflow-scroll scrollbar-hide flex flex-col w-full gap-3 max-h-[300px] bg-neutral-200 dark:bg-neutral-800 p-2 rounded-3xl'
                  >
                    {data?.notificationUser.length > 0 ? (
                      data?.notificationUser &&
                      data?.notificationUser.map((u) => {
                        let user = u?.user;
                        return (
                          <div className='flex flex-row justify-between gap-3 items-center bg-white dark:bg-neutral-700/50 w-full rounded-2xl p-3 pr-5'>
                            <div className='flex flex-row items-center gap-3 w-full'>
                              <Avatar
                                // src={getProfileAvatar(user?.profilePhoto)}
                                className='w-[40px] h-[40px]'
                              />
                              <div className='flex flex-col'>
                                <span className='font-normal text-[13px] truncate w-[150px] text-black dark:text-white'>{`${user?.surname} ${user?.name}`}</span>
                                {/* <span className='font-normal text-[13px] text-orange-500'>{`${getPhoneNumberPattern(
                                    user?.user?.phoneNumber
                                  )}`}</span> */}
                              </div>
                            </div>
                            {u?.status === "READ" ? (
                              <span className='text-green-500'>
                                <TickDouble04Icon />
                              </span>
                            ) : (
                              <span className='text-neutral-500 dark:text-neutral-300 w-[18px]'>
                                <Tick01Icon />
                              </span>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <span className='text-[14px] flex justify-center items-center'>
                        {"No data."}
                      </span>
                    )}
                  </ScrollShadow>
                </PopoverContent>
              </Popover>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default SentNotificationsPage;
