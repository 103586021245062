import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/Api";
import { findIndex } from "lodash";
import { toast } from "react-toastify";

export const getAllNotifications = createAsyncThunk(
  "notifications/getAll",
  async (body = {}) => {
    const response = await Api.get("/notifications/all");
    return response.data;
  }
);

export const getNotification = createAsyncThunk(
  "notifications/get",
  async (body) => {
    const response = await Api.get(`/notifications`);
    return response.data;
  }
);

export const getNotificationCount = createAsyncThunk(
  "notifications/getCount",
  async (body) => {
    const response = await Api.get(`/notifications/count`);
    return response.data;
  }
);

export const createNotification = createAsyncThunk(
  "notifications/create",
  async (body) => {
    const response = await Api.post("/notifications", body?.data);
    return response.data;
  }
);

export const updateNotification = createAsyncThunk(
  "notifications/update",
  async (body) => {
    const response = await Api.put(`/notifications/${body?.id}`, body);
    return response.data;
  }
);

export const deleteNotification = createAsyncThunk(
  "notifications/delete",
  async (body) => {
    const response = await Api.delete(`/notifications/${body?.id}`);
    return response.data;
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: null,
    notification: null,
    count: null,
    loading: false,
    uploadLoading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    setSalaryPayments: (state, { payload }) => {
      state.salaryPayments = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET notifications ------------------/////
      .addCase(getAllNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.loading = false;
        let ntfs = action?.payload?.data;
        if (ntfs) {
          let i = 0;
          ntfs = ntfs.map((el) => {
            i++;
            return { id: i, ...el };
          });
          state.notifications = ntfs.sort(
            (a, b) =>
              new Date(b?.notification.createdAt) -
              new Date(a?.notification.createdAt)
          );
        }
      })
      .addCase(getAllNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET notification ------------------/////
      .addCase(getNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.loading = false;
        let arr = action.payload?.data;
        arr.sort(
          (a, b) =>
            new Date(b?.notification?.createdAt) -
            new Date(a?.notification?.createdAt)
        );
        state.notifications = arr;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET notification ------------------/////
      .addCase(getNotificationCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotificationCount.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload;
      })
      .addCase(getNotificationCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ CREATE notifications ------------------/////
      .addCase(createNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNotification.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.notifications = [...state.notifications, payload?.data];
        toast.success(payload?.message);
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ delete notifications ------------------/////
      .addCase(deleteNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotification.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.notifications, { id: payload?.id });
        state.notifications.splice(ctgIndex, 1);
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE notifications ------------------/////
      .addCase(updateNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNotification.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.notifications, {
          id: payload?.data?.id,
        });
        state.notifications[ctgIndex] = payload?.data;
        toast.success(payload?.message);
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError, setSalaryPayments } = notificationsSlice.actions;

export default notificationsSlice.reducer;
