import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/Api";
import { findIndex } from "lodash";

export const getAllHisobot = createAsyncThunk(
  "statistics/getAllHisobot",
  async (body) => {
    const response = await Api.get(`/statistics`);
    return response.data;
  }
);

export const getAllHisobotByDateRange = createAsyncThunk(
  "statistics/getAllHisobotByDateRange",
  async (body) => {
    const response = await Api.get(
      `/statistics?fromDate=${body?.startDate}&toDate=${body?.endDate}`
    );
    return response.data;
  }
);

export const getAllHisobotManager = createAsyncThunk(
  "statistics/getAllHisobotManager",
  async (body) => {
    const response = await Api.get(`/statistics/payments/manager`);
    return response.data;
  }
);

export const getAllHisobotByDateRangeManager = createAsyncThunk(
  "statistics/getAllHisobotByDateRangeManager",
  async (body) => {
    const response = await Api.get(
      `/statistics/payments/manager?fromDate=${body?.startDate}&toDate=${body?.endDate}`
    );
    return response.data;
  }
);

export const getAllHisobotByBranchId = createAsyncThunk(
  "statistics/getAllHisobotByBranchId",
  async (body) => {
    const response = await Api.get(
      `/statistics/paymentsByFilialId?filialId=${body?.filialId}`
    );
    return response.data;
  }
);

export const getAllHisobotByBranchIdByDateRange = createAsyncThunk(
  "statistics/getAllHisobotByBranchIdByDateRange",
  async (body) => {
    const response = await Api.get(
      `/statistics/paymentsByFilialId?filialId=${body?.filialId}&fromDate=${body?.startDate}&toDate=${body?.endDate}`
    );
    return response.data;
  }
);

export const getAllIncomesByDateRange = createAsyncThunk(
  "statistics/getAllIncomesByDateRange",
  async (body) => {
    const response = await Api.get(
      `/statistics/income?fromDate=${body?.startDate}&toDate=${body?.endDate}`
    );
    return response.data;
  }
);

export const getAllOutcomesByDateRange = createAsyncThunk(
  "statistics/getAllOutcomesByDateRange",
  async (body) => {
    const response = await Api.get(
      `/statistics/outcome?fromDate=${body?.startDate}&toDate=${body?.endDate}`
    );
    return response.data;
  }
);

export const getAllIncomesByDateRangeManager = createAsyncThunk(
  "statistics/getAllIncomesByDateRangeManager",
  async (body) => {
    const response = await Api.get(
      `/statistics/income/manager?fromDate=${body?.startDate}&toDate=${body?.endDate}`
    );
    return response.data;
  }
);

export const getAllOutcomesByDateRangeManager = createAsyncThunk(
  "statistics/getAllOutcomesByDateRangeManager",
  async (body) => {
    const response = await Api.get(
      `/statistics/outcome/manager?fromDate=${body?.startDate}&toDate=${body?.endDate}`
    );
    return response.data;
  }
);

const statisticsSlice = createSlice({
  name: "statistics",
  initialState: {
    hisobot: null,
    incomes: null,
    outcomes: null,
    totalPrice: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    clearStatistics: (state) => {
      state.statistics = null;
    },
  },
  extraReducers: (builder) => {
    builder

      ///------------ GET Incomes By Date Range ------------------/////
      .addCase(getAllHisobot.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllHisobot.fulfilled, (state, action) => {
        state.loading = false;
        let arr = action.payload;
        state.hisobot = arr;
      })
      .addCase(getAllHisobot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      ///------------ GET Incomes By Date Range ------------------/////
      .addCase(getAllHisobotByDateRange.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllHisobotByDateRange.fulfilled, (state, action) => {
        state.loading = false;
        let arr = action.payload;
        state.hisobot = arr;
      })
      .addCase(getAllHisobotByDateRange.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET Incomes By Date Range ------------------/////
      .addCase(getAllHisobotByBranchId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllHisobotByBranchId.fulfilled, (state, action) => {
        state.loading = false;
        let arr = action.payload;
        state.hisobot = arr;
      })
      .addCase(getAllHisobotByBranchId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      ///------------ GET Incomes By Date Range ------------------/////
      .addCase(getAllHisobotByBranchIdByDateRange.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getAllHisobotByBranchIdByDateRange.fulfilled,
        (state, action) => {
          state.loading = false;
          let arr = action.payload;
          state.hisobot = arr;
        }
      )
      .addCase(getAllHisobotByBranchIdByDateRange.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET Incomes By Date Range ------------------/////
      .addCase(getAllHisobotManager.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllHisobotManager.fulfilled, (state, action) => {
        state.loading = false;
        let arr = action.payload;
        state.hisobot = arr;
      })
      .addCase(getAllHisobotManager.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      ///------------ GET Incomes By Date Range ------------------/////
      .addCase(getAllHisobotByDateRangeManager.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllHisobotByDateRangeManager.fulfilled, (state, action) => {
        state.loading = false;
        let arr = action.payload;
        state.hisobot = arr;
      })
      .addCase(getAllHisobotByDateRangeManager.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET Incomes By Date Range ------------------/////
      .addCase(getAllIncomesByDateRange.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllIncomesByDateRange.fulfilled, (state, action) => {
        state.loading = false;
        let arr = action.payload?.payments;
        arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        state.incomes = arr;
      })
      .addCase(getAllIncomesByDateRange.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET Incomes By Date Range ------------------/////
      .addCase(getAllOutcomesByDateRange.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllOutcomesByDateRange.fulfilled, (state, action) => {
        state.loading = false;
        let arr = action.payload?.payments;
        arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        state.outcomes = arr;
      })
      .addCase(getAllOutcomesByDateRange.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET Incomes By Date Range ------------------/////
      .addCase(getAllIncomesByDateRangeManager.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllIncomesByDateRangeManager.fulfilled, (state, action) => {
        state.loading = false;
        let arr = action.payload?.payments;
        arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        state.incomes = arr;
      })
      .addCase(getAllIncomesByDateRangeManager.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET Incomes By Date Range ------------------/////
      .addCase(getAllOutcomesByDateRangeManager.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllOutcomesByDateRangeManager.fulfilled, (state, action) => {
        state.loading = false;
        let arr = action.payload?.payments;
        arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        state.outcomes = arr;
      })
      .addCase(getAllOutcomesByDateRangeManager.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError, clearStatistics } = statisticsSlice.actions;

export default statisticsSlice.reducer;
