import React, { useEffect } from "react";
import ProTable from "../../components/ProTable";
import { useDispatch, useSelector } from "react-redux";
import {
  fields,
  emptyValues,
  validationSchema,
  columns,
  INITIAL_VISIBLE_COLUMNS,
  searchIndexes,
} from "./data";
import {
  createEmployee,
  deleteEmployee,
  getAllEmployees,
  getAllEmployeesBranch,
  getAllEmployeesByBranchId,
  updateEmployee,
} from "./employeesSlice";
import { getAllBranches } from "../BranchesPage/branchesSlice";
import { changeBC } from "../DashboardPage/dashboardSlice";
import { BASE_URL } from "../../config/Api";

const EmployeesPage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { employees } = useSelector((state) => state.employees);
  const { branches, chosenBranch } = useSelector((state) => state.branches);

  useEffect(() => {
    dispatch(changeBC([{ name: "Ишчилар", link: "/employees" }]));

    dispatch(getAllBranches());
  }, []);

  useEffect(() => {
    if (user) {
      // if (user?.roles === "MANAGER") {
      //   dispatch(getAllReportByBranchId(user?.assignedFilialId));
      // } else
      if (user?.roles === "ADMIN") {
        if (chosenBranch) {
          dispatch(getAllEmployeesByBranchId(chosenBranch?.id));
        } else {
          dispatch(getAllEmployees());
        }
      } else {
        dispatch(getAllEmployeesBranch());
      }
    }
  }, [user]);

  return (
    <div className='flex flex-col w-full px-5 gap-8 h-full'>
      <div className='tableContent'>
        {employees && branches && (
          <ProTable
            exportData={{
              link: chosenBranch
                ? `/worker/export/${chosenBranch?.id}`
                : `/worker/export`,
              fileName: "export_worker.xlsx",
            }}
            searchIndexes={searchIndexes}
            viewButtonUrl='/employees'
            createSubmitHandler={(reqBody) =>
              dispatch(
                createEmployee({
                  currentSalary: +reqBody?.currentSalary,
                  filialId: +reqBody?.filialId,
                  ...reqBody,
                })
              )
            }
            editSubmitHandler={(reqBody) => dispatch(updateEmployee(reqBody))}
            deleteSubmitHandler={(id) => dispatch(deleteEmployee({ id }))}
            columns={columns}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
            tableData={employees}
            createData={{
              fields: fields.map((el) => {
                if (el.name === "filialId") {
                  el.selectData = branches.map((el) => ({
                    name: el?.name,
                    ...el,
                  }));
                  return el;
                }

                return el;
              }),
              initialValues: emptyValues,
              validationSchema,
            }}
            editData={{
              fields,
              initialValues: (data) => data,
              validationSchema,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeesPage;
