import {
  BookOpenIcon,
  BuildingOffice2Icon,
  ChartBarIcon,
  ClipboardDocumentListIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import {
  AnalyticsUpIcon,
  Building06Icon,
  DashboardSpeed02Icon,
  DashboardSquare02Icon,
  DocumentValidationIcon,
  GoogleDocIcon,
  Home01Icon,
  Home03Icon,
  UserGroupIcon,
  UserMultipleIcon,
} from "hugeicons-react";

export const sidebarMenus = [
  {
    pathName: "/home",
    name: "Бош саҳифа",
    icon: <Home01Icon stroke='2' />,
  },
  {
    pathName: "/agreements",
    name: "Шартномалар",
    icon: <DocumentValidationIcon className='w-[21px] stroke-[1.5px]' />,
  },
  {
    pathName: "/report",
    name: "Ҳисобот",
    icon: <GoogleDocIcon className='w-[21px] stroke-[1.5px]' />,
  },
  {
    pathName: "/profilePD",
    name: "Адтел закуп",
    icon: <DashboardSquare02Icon className='w-[21px] stroke-[1.5px]' />,
  },
  {
    pathName: "/employees",
    name: "Ишчилар",
    icon: <UserMultipleIcon className='w-[21px] stroke-[1.5px]' />,
  },
  {
    pathName: "/users",
    name: "Фойдаланувчилар",
    icon: <UserGroupIcon className='w-[21px] stroke-[1.5px]' />,
  },
  {
    pathName: "/statistics",
    name: "Статистика",
    icon: <AnalyticsUpIcon className='w-[21px] stroke-[1.5px]' />,
  },

  {
    pathName: "/branches",
    name: "Филиаллар",
    icon: <Building06Icon className='w-[21px] stroke-[1.5px]' />,
  },
  // {
  //   pathName: "/employees",
  //   name: "Xodimlar",
  //   icon: <UserGroupIcon className='w-[21px] stroke-[1.5px]' />,
  // },
];
