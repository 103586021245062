import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "./notificationsSlice";
import NotificationCard from "../../components/Notifications/NotificationCard";
import { ScrollShadow } from "@nextui-org/react";

const NotificationsModal = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);

  useEffect(() => {
    dispatch(getNotification());
  }, []);
  return (
    <div className='flex flex-col w-[400px] py-5 px-3 max-h-[85vh]'>
      <span className='text-black dark:text-white text-[16px] font-semibold'>
        {"Билдиришномалар"}
      </span>
      <ScrollShadow className='flex flex-col w-full gap-1 my-2 scrollbar-hide'>
        {notifications &&
          notifications?.map((el) => <NotificationCard ntf={el} />)}
      </ScrollShadow>
    </div>
  );
};

export default NotificationsModal;
