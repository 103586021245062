import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import date from "date-and-time";
import {
  changeBC,
  getFilialTotal,
  getLeastIncome,
  getLeastOutcome,
  getMostIncome,
  getMostOutcome,
  getTotalIncomeOutcome,
  getUserTotal,
} from "../DashboardPage/dashboardSlice";
import { DateRangePicker, link } from "@nextui-org/react";
import TotalCard from "../../components/Dashboard/TotalCard";
import { UsersIcon } from "@heroicons/react/24/outline";
import {
  Building02Icon,
  Calendar02Icon,
  MoneyReceive02Icon,
  MoneyReceiveCircleIcon,
  MoneyReceiveSquareIcon,
  MoneySend02Icon,
  MoneySendCircleIcon,
  UserGroupIcon,
  UserMultiple02Icon,
} from "hugeicons-react";
import { fromDate, parseDate } from "@internationalized/date";
import { FormatDate } from "../../utils/date";
import BranchCard from "../../components/Dashboard/BranchCard";

const HomePage = () => {
  const [dates, setDates] = useState(null);
  const dispatch = useDispatch();
  const {
    userTotal,
    totalIncome,
    totalOutcome,
    filialTotal,
    mostIncome,
    mostOutcome,
    leastIncome,
    leastOutcome,
  } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(changeBC([{ name: "Бош саҳифа", link: "/home" }]));
    dispatch(getUserTotal());
    dispatch(getTotalIncomeOutcome());
    dispatch(getFilialTotal());

    let fromDate = new Date();
    let toDate = new Date();
    fromDate.setDate(toDate.getDate() - 30);
    setDates({ fromDate, toDate });
  }, []);

  useEffect(() => {
    if (dates) {
      dispatch(
        getMostIncome({
          startDate: dates?.fromDate.toISOString(),
          endDate: dates?.toDate.toISOString(),
        })
      );
      dispatch(
        getMostOutcome({
          startDate: dates?.fromDate.toISOString(),
          endDate: dates?.toDate.toISOString(),
        })
      );

      dispatch(
        getLeastIncome({
          startDate: dates?.fromDate.toISOString(),
          endDate: dates?.toDate.toISOString(),
        })
      );
      dispatch(
        getLeastOutcome({
          startDate: dates?.fromDate.toISOString(),
          endDate: dates?.toDate.toISOString(),
        })
      );
    }
  }, [dates]);

  return (
    <div className='flex flex-col gap-3 w-full px-5'>
      <div className='grid grid-cols-4 gap-5'>
        <TotalCard
          title={"Фойдаланувчилар"}
          content={userTotal}
          icon={
            <UserMultiple02Icon className='stroke-[1.8px] w-[35px] h-[35px] p-2 text-[#ffa200] bg-[#ffa200]/30 rounded-xl' />
          }
        />
        <TotalCard
          title={"Фойда"}
          isMoney
          content={totalIncome}
          icon={
            <MoneyReceive02Icon className='stroke-[1.8px] w-[35px] h-[35px] p-2 text-[#04d100] bg-[#04d100]/30 rounded-xl' />
          }
        />
        <TotalCard
          isMoney
          title={"Харажатлар"}
          content={totalOutcome}
          icon={
            <MoneySend02Icon className='stroke-[1.8px] w-[35px] h-[35px] p-2 text-[#cb62dd] bg-[#cb62dd]/30 rounded-xl' />
          }
        />

        <TotalCard
          title={"Филиаллар"}
          content={filialTotal}
          icon={
            <Building02Icon className='stroke-[1.8px] w-[35px] h-[35px] p-2 text-[#00acfb] bg-[#00acfb]/30 rounded-xl' />
          }
        />
      </div>
      <div className='flex flex-row gap-5 w-full items-center mt-3'>
        <div className='flex flex-row items-center gap-1 bg-neutral-100 dark:bg-zinc-800 rounded-full p-1'>
          <div className='flex justify-center items-center h-[50px] px-5 bg-white dark:bg-zinc-700/50 border-[1.3px] border-transparent rounded-full'>
            <span className='font-bold font-space text-[18px] text-black dark:text-white'>
              {dates && date.format(dates?.fromDate, "DD MMM YYYY")}
            </span>
          </div>
          <div className='flex justify-center items-center w-[13px] h-[13px] bg-blue-400 dark:bg-blue-600 rounded-full'></div>
          <div className='flex justify-center items-center h-[50px] px-5 bg-white rounded-full dark:bg-zinc-700/50'>
            <span className='font-bold font-space text-[18px] text-black dark:text-white'>
              {dates && date.format(dates?.toDate, "DD MMM YYYY")}
            </span>
          </div>
        </div>
        <DateRangePicker
          defaultValue={{
            start: parseDate(
              FormatDate(
                (() => {
                  let dt = new Date();
                  dt.setDate(dt.getDate() - 30);
                  return dt;
                })()
              )
            ),
            end: parseDate(FormatDate(new Date())),
          }}
          onChange={(date) => {
            if (date) {
              setDates({
                fromDate: new Date(date?.start),
                toDate: new Date(date?.end),
              });
            }
          }}
          size='lg'
          visibleMonths={2}
          selectorIcon={<Calendar02Icon className='text-blue-700' />}
          classNames={{
            input: "invisible hidden bg-transparent",
            inputWrapper:
              "w-[50px] h-[50px] text-[0px] bg-white dark:bg-zinc-700/60 rounded-full p-0 shadow-none",
            selectorButton:
              "w-[50px] h-[50px] hover:bg-blue-500 text-blue-700 dark:text-white",
            // calendarContent: "bg-red-500",
            timeInput: "hidden",
            timeInputWrapper: "bg-transparent",
            segment: "bg-transparent",
            base: "bg-transparent border-none shadow-none w-fit",
          }}
        />
      </div>
      <div className='grid grid-cols-4 gap-5'>
        <BranchCard
          icon={
            <MoneyReceiveCircleIcon className='stroke-[1.8px] w-[35px] h-[35px] p-2 text-black dark:text-white bg-green-300/40 rounded-full' />
          }
          title={"Энг кўп фойда"}
          branch={mostIncome}
          amountStyle={
            "text-[28px] font-space font-bold text-green-600 dark:text-green-500"
          }
        />
        <BranchCard
          icon={
            <MoneyReceiveCircleIcon className='stroke-[1.8px] w-[35px] h-[35px] p-2 text-black dark:text-white bg-amber-300/30 rounded-full' />
          }
          title={"Энг кам фойда"}
          branch={leastIncome}
          amountStyle={
            "text-[28px] font-space font-bold text-orange-600 dark:text-orange-400"
          }
        />
        <BranchCard
          icon={
            <MoneySendCircleIcon className='stroke-[1.8px] w-[35px] h-[35px] p-2 text-black dark:text-white bg-red-300/40 rounded-full' />
          }
          title={"Энг кўп харажат"}
          branch={mostOutcome}
          amountStyle={
            "text-[28px] font-space font-bold text-red-600 dark:text-red-400"
          }
        />
        <BranchCard
          icon={
            <MoneySendCircleIcon className='stroke-[1.8px] w-[35px] h-[35px] p-2 text-black dark:text-white bg-amber-300/30 rounded-full' />
          }
          title={"Энг кам харажат"}
          branch={leastOutcome}
          amountStyle={
            "text-[28px] font-space font-bold text-amber-600 dark:text-amber-500"
          }
        />
      </div>
    </div>
  );
};

export default HomePage;
