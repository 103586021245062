import React, { useEffect } from "react";
import ProTable from "../../../components/ProTable";
import { useDispatch, useSelector } from "react-redux";

import {
  columns,
  emptyValues,
  fields,
  INITIAL_VISIBLE_COLUMNS,
  searchIndexes,
  units,
  validationSchema,
} from "./data";
import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbItem, Breadcrumbs, Divider } from "@nextui-org/react";
import { BanknotesIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { getMoneyPattern } from "../../../utils/regex";
import {
  clearDatas,
  createCD,
  deleteCD,
  getCDsById,
  getProfileCDById,
  getProfileCDRemainingById,
  getProfileCDsById,
  getProfileCDTotalById,
  updateCD,
} from "../cdSlice";
import { changeBC } from "../../DashboardPage/dashboardSlice";

const CDPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  // const { branches } = useSelector((state) => state.branches);
  const { pds, profileCD, total, remaining } = useSelector(
    (state) => state.profileCD
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearDatas());
    dispatch(getProfileCDById(id));
    dispatch(getProfileCDTotalById(id));
    dispatch(getProfileCDRemainingById(id));
    dispatch(getCDsById(id));
    // dispatch(getReportPaymentsById({ id: id }));

    return () => {
      // dispatch(clearReport());
    };
  }, []);

  useEffect(() => {
    profileCD &&
      dispatch(
        changeBC([
          { name: "Адтел закуп", link: "/profilePD" },
          { name: profileCD?.name, link: "/profilePD" },
        ])
      );
  }, [profileCD]);

  return (
    profileCD && (
      <div className='flex flex-col w-full px-5 h-full '>
        <div className='flex flex-col p-5 border-[0.5px] bg-white dark:bg-[#101112] rounded-3xl dark:border-neutral-800'>
          {/* <Breadcrumbs radius='lg' variant='solid'>
            <BreadcrumbItem onClick={() => navigate("/profileCD")}>
              {"Адтель закуп"}
            </BreadcrumbItem>
            <BreadcrumbItem>{profileCD?.name}</BreadcrumbItem>
          </Breadcrumbs> */}
          <div className='flex flex-col p-3 bg-neutral rounded-xl gap-5'>
            <div className='flex flex-col w-full gap-5'>
              <div className='flex flex-col w-full col-span-2 gap-3'>
                <div className='grid grid-cols-2 w-full gap-3'>
                  <div className='flex flex-row gap-5 w-full bg-white border-[0.8px] dark:border-zinc-700 dark:bg-zinc-900 shadow-lg rounded-2xl py-4 px-5 h-fit'>
                    <CurrencyDollarIcon className='w-[36px] text-green-500 mb-1' />
                    <div className='flex flex-col gap-[1px] '>
                      <span className='text-[14px] text-neutral-500 font-semibold'>
                        {"Тўланган сумма"}
                      </span>
                      <span className='text-[16px] text-black dark:text-white font-bold mb-1'>
                        {`${getMoneyPattern(total, ",")} UZS`}
                      </span>
                    </div>
                  </div>
                  <div className='flex flex-row gap-5 w-full bg-white border-[0.8px] dark:border-zinc-700 dark:bg-zinc-900 shadow-lg rounded-2xl py-4 px-5 h-fit'>
                    <BanknotesIcon className='w-[28px] text-pink-500 mb-1' />

                    <div className='flex flex-col gap-[1px] '>
                      <span className='text-[14px] text-neutral-500 font-semibold'>
                        {"Қарздорлик"}
                      </span>
                      <span className='text-[16px] text-black dark:text-white font-bold mb-1'>
                        {`${getMoneyPattern(remaining, ",")} UZS`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col w-full col-span-3 bg-white border-[0.8px] dark:border-zinc-700 dark:bg-zinc-900/80 px-5 py-2 rounded-xl'>
                <span className='font-bold text-[18px] text-primary mb-2'>
                  {"Тўловлар"}
                </span>
                <div className='flex flex-col w-full items-center gap-5'>
                  {pds && (
                    <ProTable
                      tableName="To'lov"
                      searchIndexes={searchIndexes}
                      // viewButtonUrl='/report/payments'
                      createSubmitHandler={(reqBody) => {
                        console.log(reqBody);
                        dispatch(
                          createCD({
                            ...reqBody,
                            profileCDId: id,
                          })
                        );
                      }}
                      editSubmitHandler={(reqBody) => {
                        console.log(reqBody);
                        dispatch(updateCD({ ...reqBody }));
                      }}
                      deleteSubmitHandler={(id) => dispatch(deleteCD({ id }))}
                      columns={columns}
                      initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
                      tableData={pds}
                      createData={{
                        fields: fields.map((el) => {
                          if (el.name === "unitOfMeasure") {
                            el.selectData = units.map((el) => ({
                              name: el?.name,
                              ...el,
                            }));
                            return el;
                          }

                          return el;
                        }),
                        initialValues: emptyValues,
                        validationSchema,
                      }}
                      editData={{
                        fields,
                        initialValues: (data) => ({
                          description: data?.description,
                          // fullAmount: data?.fullAmount,
                          id: data?.id,
                          name: data?.name,
                          number: data?.number,
                          payment: data?.payment,
                          price: data?.price,
                          typeOfUnit: data?.typeOfUnit,
                          unitOfMeasure: data?.unitOfMeasure,
                        }),
                        validationSchema,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default CDPage;
