import React from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options = {
//   responsive: true,
//   scales: {
//     x: {
//       title: {
//         display: false,
//       },
//       grid: {
//         display: false,
//       },
//       ticks: {
//         color: "#718096",
//       },
//     },
//     y: {
//       title: {
//         display: false,
//       },
//       min: 0,
//       // max: 80000,
//       // ticks: {
//       //   stepSize: 20000,
//       //   color: "#718096",
//       //   callback: function (value) {
//       //     return value * 0.001 + " K";
//       //   },
//       // },
//       grid: {
//         color: "#EDF2F7",
//       },
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//       labels: {
//         font: {
//           size: 12,
//           weight: 500,
//         },
//         color: "#2D3748",
//         boxWidth: 8,
//         boxHeight: 8,
//         usePointStyle: true,
//         pointStyle: "circle",
//       },
//     },
//     // title: {
//     //   display: true,
//     //   text: "Chart.js Line Chart",
//     // },
//   },
// };

const BarChart = React.memo(({ datas, options }) => {
  return <Bar options={options} data={datas} className='w-full mb-10' />;
});

export default BarChart;
