import * as Yup from "yup";

export const userFields = [
  {
    name: "name",
    type: "text",
    label: "Исми",
    placeholder: "Исмини киритинг...",
    isRequired: true,
  },
  {
    name: "surname",
    type: "text",
    label: "Фамилия",
    placeholder: "Фамилияни киритинг...",
    isRequired: true,
  },

  {
    name: "username",
    type: "text",
    label: "Username",
    placeholder: "Usernameni kiriting...",
  },
  {
    name: "phoneNumber",
    type: "text",
    label: "Тел. рақами",
    placeholder: "Тел. рақамини киритинг...",
  },
];

export const userEmptyValues = {
  name: "",
  surname: "",
  phoneNumber: "",
  username: "",
};

export const userValidationSchema = Yup.object().shape({
  name: Yup.string().required("Бўш бўлмаслиги керак!"),
  surname: Yup.string().required("Бўш бўлмаслиги керак!"),
  phoneNumber: Yup.string().required("Бўш бўлмаслиги керак!"),
  username: Yup.string().required("Бўш бўлмаслиги керак!"),
});

export const passwordFields = [
  {
    name: "oldPassword",
    type: "text",
    label: "Эски парол",
    placeholder: "Эски паролни киритинг...",
    isRequired: true,
  },
  {
    name: "newPassword",
    type: "text",
    label: "Янги парол",
    placeholder: "Янги паролни киритинг...",
    isRequired: true,
  },
];

export const passwordEmptyValues = {
  oldPassword: "",
  newPassword: "",
};

export const passwordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Бўш бўлмаслиги керак!"),
  newPassword: Yup.string().required("Бўш бўлмаслиги керак!"),
});
