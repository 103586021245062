import Api from "./Api";

export const DownloadFile = async (urlLink, fileName) => {
  try {
    const response = await Api.get(urlLink, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (err) {
    if (!err.response) {
      throw err;
    }
  }
};
