import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProTable from "../../components/ProTable";
import * as JsSearch from "js-search";
import {
  createProfilePD,
  deleteProfilePD,
  getAllProfilePDs,
  getAllProfilePDsBranch,
  getAllProfilePDsByBranchId,
  updateProfilePD,
} from "./pdSlice";
import { getAllBranches } from "../BranchesPage/branchesSlice";
import {
  INITIAL_VISIBLE_COLUMNS,
  columns,
  emptyValues,
  fields,
  searchIndexes,
  validationSchema,
} from "./data";
// import { getAllProfilePDCategories } from "../Categories/categoriesSlice";
import CreateModal from "../../components/Modals/CreateModal";
import {
  Button,
  Input,
  Pagination,
  Select,
  SelectItem,
} from "@nextui-org/react";
import StoreCard from "../../components/Store/StoreCard";
import EditModal from "../../components/Modals/EditModal";
import DeleteModal from "../../components/Modals/DeleteModal";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { changeBC } from "../DashboardPage/dashboardSlice";
import { DownloadFile } from "../../config/downloadFile";
import ExportButton from "../../components/ExportButton";

const PDPage = () => {
  const itemPerPage = 20;
  const { user } = useSelector((state) => state.auth);
  const { profilePDs } = useSelector((state) => state.profilePD);
  const { branches, chosenBranch } = useSelector((state) => state.branches);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [value, setValue] = React.useState("");
  const [profilePDsData, setProfilePDsData] = React.useState([]);

  const [filterValue, setFilterValue] = React.useState("");

  const handleSelectionChange = (e) => {
    console.log(e.target.value);
    setValue(e.target.value);
  };
  // const { profilePDCategories } = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeBC([{ name: "Адтел закуп", link: "/profilePD" }]));

    dispatch(getAllBranches());
    // dispatch(getAllProfilePDCategories());
  }, []);

  useEffect(() => {
    if (user) {
      if (user?.roles === "ADMIN") {
        if (chosenBranch) {
          dispatch(getAllProfilePDsByBranchId(chosenBranch?.id));
        } else {
          dispatch(getAllProfilePDs());
        }
      } else {
        dispatch(getAllProfilePDsBranch());
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user?.roles === "MANAGER") {
        dispatch(getAllProfilePDsByBranchId(user?.assignedFilialId));
      } else if (user?.roles === "ADMIN") {
        dispatch(getAllProfilePDs());
      } else {
      }
    }
  }, [user]);

  const hasSearchFilter = Boolean(filterValue);

  useEffect(() => {
    if (profilePDs) {
      let filteredDatas = [...profilePDs];

      if (hasSearchFilter) {
        var search = new JsSearch.Search("id");

        search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
        search.searchIndex = new JsSearch.UnorderedSearchIndex();
        JsSearch.StopWordsMap.bob = true;

        searchIndexes.map((element) => {
          search.addIndex(element);
        });

        search.addDocuments([...profilePDs]);

        filteredDatas = search.search(filterValue);
      }
      setProfilePDsData(filteredDatas);
    }
  }, [filterValue]);

  const onSearchChange = useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setCurrentPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    setProfilePDsData(profilePDs);
  }, [profilePDs]);

  return (
    <div className='flex flex-col w-full px-5 gap-8 h-full'>
      <div className='tableContent'>
        {profilePDsData && branches && (
          <div className='flex flex-col gap-5 items-center'>
            <div className='flex flex-row items-center w-full justify-between'>
              <div className='flex flex-row items-center gap-5'>
                <Input
                  radius='lg'
                  // variant='bordered'
                  size='md'
                  isClearable
                  className='w-[400px] font-semibold'
                  placeholder='Қидириш...'
                  startContent={<MagnifyingGlassIcon className='w-[18px]' />}
                  value={filterValue}
                  onClear={() => onClear()}
                  onValueChange={onSearchChange}
                />
                {/* <Select
                  radius='lg'
                  size='sm'
                  label='Kategoriya'
                  variant='bordered'
                  color='primary'
                  placeholder='Kategoriya tanlash'
                  selectedKeys={[value]}
                  className='w-[300px] text-black text-[16px]'
                  onChange={handleSelectionChange}
                >
                  {profilePDCategories.map((ctg) => (
                    <SelectItem
                      key={ctg?.id}
                      value={ctg?.id}
                      className='text-black'
                    >
                      {ctg?.name}
                    </SelectItem>
                  ))}
                </Select> */}
              </div>
              <div className='flex flex-row gap-2 items-center'>
                <ExportButton
                  exportData={{
                    link: "/profilePD/export",
                    fileName: "export_pd.xlsx",
                  }}
                />
                <CreateModal
                  // ctgs={categories}
                  handleSubmit={(reqBody) => {
                    dispatch(createProfilePD(reqBody));
                  }}
                  btnText={`Маълумот қўшиш`}
                  fields={fields.map((el) => {
                    if (el.name === "filialId") {
                      el.selectData = branches.map((el) => ({
                        name: el?.name,
                        ...el,
                      }));
                      return el;
                    }

                    return el;
                  })}
                  // fields={fields.map((el) => {
                  //   if (el.name === "categoryProfilePDId") {
                  //     el.selectData = profilePDCategories;
                  //     return el;
                  //   }

                  //   return el;
                  // })}
                  validationSchema={validationSchema}
                  initialValues={emptyValues}
                />
              </div>
            </div>
            {profilePDsData.length > 0 ? (
              <div className='grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 w-full gap-5 flex-wrap justify-around'>
                {profilePDsData
                  .filter((el) => {
                    if (value === "") {
                      return true;
                    } else {
                      return el?.categoryProfilePD?.id === +value;
                    }
                  })
                  .slice(
                    (currentPage - 1) * itemPerPage,
                    (currentPage - 1) * itemPerPage + itemPerPage
                  )
                  .map((profilePD, index) => (
                    <StoreCard
                      link={"/profilePD/pd"}
                      key={index}
                      store={profilePD}
                      deleteModal={(str) => (
                        <DeleteModal
                          button={<Button color='danger'>{"Ўчириш"}</Button>}
                          contextText={str?.name + ""}
                          handleSubmit={() =>
                            dispatch(deleteProfilePD({ id: str?.id }))
                          }
                        />
                      )}
                      editModal={(str) => (
                        <EditModal
                          button={
                            <Button
                              color='warning'
                              variant='shadow'
                              className='w-fit text-white'
                            >
                              {"Таҳрирлаш"}
                            </Button>
                          }
                          // ctgs={categories}
                          handlerSubmit={(reqBody) =>
                            dispatch(updateProfilePD(reqBody))
                          }
                          fields={fields}
                          //   .map((el) => {

                          //   if (el.name === "categoryProfilePDId") {
                          //     el.selectData = profilePDCategories;
                          //     return el;
                          //   }

                          //   return el;
                          // })}
                          validationSchema={validationSchema}
                          initialValues={{
                            id: str?.id,
                            name: str?.name,
                            description: str?.description,

                            filialId: str?.filial?.id + "",
                          }}
                        />
                      )}
                    />
                  ))}
              </div>
            ) : (
              <div className='flex w-full justify-center items-center'>
                <span>{"Маълумот топилмади."}</span>
              </div>
            )}
            <Pagination
              total={Math.ceil(
                profilePDsData.filter((el) => {
                  if (value === "") {
                    return true;
                  } else {
                    return el?.categoryProfilePD?.id === +value;
                  }
                }).length / itemPerPage
              )}
              className='mt-5'
              color='primary'
              page={currentPage}
              onChange={setCurrentPage}
            />
            {/* <ProTable
              searchIndexes={searchIndexes}
              tableName="Do'kon"
              // viewButtonUrl='/profilePDs'
              createSubmitHandler={(reqBody) => {
                dispatch(createProfilePD(reqBody));
              }}
              editSubmitHandler={(reqBody) => dispatch(updateProfilePD(reqBody))}
              deleteSubmitHandler={(id) => dispatch(deleteProfilePD({ id }))}
              columns={columns}
              initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
              tableData={profilePDs}
              createData={{
                fields: fields.map((el) => {
                  if (el.name === "categoryProfilePDId") {
                    el.selectData = profilePDCategories;
                    return el;
                  }

                  return el;
                }),
                initialValues: emptyValues,
                validationSchema,
              }}
              editData={{
                fields: fields.map((el) => {
                  if (el.name === "categoryProfilePDId") {
                    el.selectData = profilePDCategories;
                    return el;
                  }

                  return el;
                }),
                initialValues: (user) => {
                  return {
                    categoryProfilePDId: user?.categoryProfilePD?.id + "",
                    ...user,
                  };
                },
                validationSchema,
              }}
            /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default PDPage;
