import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/Api";
import { findIndex } from "lodash";
import { toast } from "react-toastify";

export const getAllProfileCDs = createAsyncThunk(
  "profileCDs/getAll",
  async (body = {}) => {
    const response = await Api.get("/profileCD");
    return response.data;
  }
);

export const getAllProfileCDsBranch = createAsyncThunk(
  "profileCDs/getAllBranch",
  async (body = {}) => {
    const response = await Api.get(`/profileCD/filial`);
    return response.data;
  }
);

export const getAllProfileCDsByBranchId = createAsyncThunk(
  "profileCDs/getAllbyBranchId",
  async (body = {}) => {
    const response = await Api.get(`/profileCD/filial/${body}`);
    return response.data;
  }
);

export const getProfileCDById = createAsyncThunk(
  "profileCDs/getById",
  async (body = {}) => {
    const response = await Api.get(`/profileCD/${body}`);
    return response.data;
  }
);

export const getProfileCDRemainingById = createAsyncThunk(
  "profileCDs/getRemainingById",
  async (body = {}) => {
    const response = await Api.get(
      `/costumerDepartment/remaining-payment/${body}`
    );
    return response.data;
  }
);

export const getProfileCDTotalById = createAsyncThunk(
  "profileCDs/getTotalById",
  async (body = {}) => {
    const response = await Api.get(`/costumerDepartment/full-amount/${body}`);
    return response.data;
  }
);

export const getCDsById = createAsyncThunk(
  "profileCDs/getProfileCDPsById",
  async (body = {}) => {
    const response = await Api.get(`/costumerDepartment/profileCD/${body}`);
    return response.data;
  }
);

export const createProfileCD = createAsyncThunk(
  "profileCDs/create",
  async (body) => {
    const response = await Api.post("/profileCD", body);
    return response.data;
  }
);

export const updateProfileCD = createAsyncThunk(
  "profileCDs/update",
  async (body) => {
    const response = await Api.put(`/profileCD/${body?.id}`, body);
    return response.data;
  }
);

export const deleteProfileCD = createAsyncThunk(
  "profileCDs/delete",
  async (body) => {
    const response = await Api.delete(`/profileCD/${body?.id}`);
    return response.data;
  }
);

export const uploadImage = createAsyncThunk(
  "profileCDs/uploadImage",
  async (body, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("file", body?.file);
    try {
      const response = await Api.post("/fayl/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      let reqBody = {
        fileEntityId: response?.data?.id,
        ...body?.profileCDData,
      };
      const res = await Api.put(`/profileCD/${reqBody?.id}`, reqBody);

      body?.removeSelection();
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createCD = createAsyncThunk(
  "profileCDs/createCD",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/costumerDepartment`, body);
      // body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCD = createAsyncThunk(
  "profileCDs/updateCD",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/costumerDepartment/${body?.id}`, body);
      // body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCD = createAsyncThunk(
  "profileCDs/deleteCD",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/costumerDepartment/${+body?.id}`);
      // body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const profileCDsSlice = createSlice({
  name: "profileCDs",
  initialState: {
    profileCDs: null,
    cds: null,
    profileCD: null,
    loading: false,
    total: null,
    remaining: null,
    uploadLoading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    clearDatas: (state) => {
      state.profileCD = null;
      state.profileCDs = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET profileCDs ------------------/////
      .addCase(getAllProfileCDs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProfileCDs.fulfilled, (state, action) => {
        state.loading = false;
        state.profileCDs = action.payload?.content;
      })
      .addCase(getAllProfileCDs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profileCDs ------------------/////
      .addCase(getAllProfileCDsBranch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProfileCDsBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.profileCDs = action.payload?.content;
      })
      .addCase(getAllProfileCDsBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profileCDs ------------------/////
      .addCase(getAllProfileCDsByBranchId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProfileCDsByBranchId.fulfilled, (state, action) => {
        state.loading = false;
        state.profileCDs = action.payload?.content;
      })
      .addCase(getAllProfileCDsByBranchId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profileCDs ------------------/////
      .addCase(getCDsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCDsById.fulfilled, (state, action) => {
        state.loading = false;
        state.pds = action.payload;
      })
      .addCase(getCDsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profileCDs ------------------/////
      .addCase(getProfileCDById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfileCDById.fulfilled, (state, action) => {
        state.loading = false;
        state.profileCD = action.payload;
      })
      .addCase(getProfileCDById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profileCDs ------------------/////
      .addCase(getProfileCDTotalById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfileCDTotalById.fulfilled, (state, action) => {
        state.loading = false;
        state.total = action.payload;
      })
      .addCase(getProfileCDTotalById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profileCDs ------------------/////
      .addCase(getProfileCDRemainingById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfileCDRemainingById.fulfilled, (state, action) => {
        state.loading = false;
        state.remaining = action.payload;
      })
      .addCase(getProfileCDRemainingById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ CREATE profileCDs ------------------/////
      .addCase(createProfileCD.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProfileCD.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.profileCDs = [payload?.data, ...state.profileCDs];
        toast.success(payload?.message);
      })
      .addCase(createProfileCD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ delete profileCDs ------------------/////
      .addCase(deleteProfileCD.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProfileCD.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.profileCDs, { id: payload?.data?.id });
        state.profileCDs.splice(ctgIndex, 1);
        toast.success(payload?.message);
      })
      .addCase(deleteProfileCD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE profileCDs ------------------/////
      .addCase(updateProfileCD.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfileCD.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.profileCDs, { id: payload?.data?.id });

        state.profileCDs[ctgIndex] = payload?.data;
        toast.success(payload?.message);
      })
      .addCase(updateProfileCD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE profileCDs ------------------/////
      .addCase(uploadImage.pending, (state) => {
        state.uploadLoading = true;
      })
      .addCase(uploadImage.fulfilled, (state, { payload }) => {
        state.uploadLoading = false;
        const ctgIndex = findIndex(state.profileCDs, { id: payload?.id });
        payload.itemCount = state.profileCDs[ctgIndex]?.itemCount;
        state.profileCDs[ctgIndex] = payload;
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.uploadLoading = false;
        state.error = action.payload;
      })

      ///------------ CREATE profileCD pD ------------------/////
      .addCase(createCD.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCD.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.pds = [...state.pds, payload?.data];
        toast.success(payload?.message);
      })
      .addCase(createCD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE profileCD pD ------------------/////
      .addCase(updateCD.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCD.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.pds, {
          id: payload?.data?.id,
        });
        state.pds[ctgIndex] = payload?.data;
        toast.success(payload?.message);
      })
      .addCase(updateCD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ delete pD ------------------/////
      .addCase(deleteCD.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCD.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.pds, {
          id: payload?.data?.id,
        });
        state.pds.splice(ctgIndex, 1);
        toast.success(payload?.message);
      })
      .addCase(deleteCD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError, clearDatas } = profileCDsSlice.actions;

export default profileCDsSlice.reducer;
