import React, { useEffect } from "react";
import ProSidebar from "../../components/Sidebar/ProSidebar";
import { Route, Routes, useNavigate, Navigate } from "react-router";
import UsersPage from "../UsersPage/UsersPage";
// import CategoriesPage from "../Categories/CategoriesPage";
// import IncomesPage from "../IncomesPage/IncomesPage";
// import OutgoingsPage from "../OutcomesPage/OutgoingsPage";
import EmployeesPage from "../EmployeesPage/EmployeesPage";

import EmployeePage from "../EmployeesPage/EmployeePage";
import BranchesPage from "../BranchesPage/BranchesPage";
import ReportPage from "../ReportPage/ReportPage";
import ChooseBranchPage from "../BranchesPage/ChooseBranchPage";
import ReportPaymentsPage from "../ReportPage/ReportPayments/ReportPaymentsPage";
import ProfilePdPage from "../PDPage/ProfilePdPage";
import PDPage from "../PDPage/PDPage/PDPage";
import {
  Badge,
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { Layout01Icon, Notification03Icon, Sun03Icon } from "hugeicons-react";
import { useTheme } from "next-themes";
import LightDarkSwitch from "../../components/LightDarkSwitch";
import HomePage from "../HomePage/HomePage";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../Login/authSlice";
import StatisticsPage from "../StatisticsPage/StatisticsPage";
import CDPage from "../PDPage/CDPage /CDPage";
import HisobotPage from "../HisobotPage/HisobotPage";
import NotificationsPage from "../NotificationsPage/NotificationsPage";
import { getNotificationCount } from "../NotificationsPage/notificationsSlice";
import NotificationsModal from "../NotificationsPage/NotificationsModal";
import ProfileButton from "../../components/ProfileButton";
import ProfilePage from "../ProfilePage/ProfilePage";
import { changeCollapsed } from "./dashboardSlice";

// import StatisticsPage from "../StatisticsPage";
// import StoresPage from "../StoresPage/StoresPage";
// import StorePage from "../StoresPage/StorePage/StorePage";
// import AgreementsPage from "../AgreementsPage/AgreementsPage";
// import RentStorePage from "../AgreementsPage/RentStorePage/RentStorePage";
// import SaleStorePage from "../AgreementsPage/SaleStorePage/SaleStorePage";
// import AdsPage from "../AdsPage/AdsPage";
// import AdsItemPage from "../AdsPage/AdsItemPage/AdsItemPage";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { theme, setTheme } = useTheme();

  const { breadcrumbs } = useSelector((state) => state.dashboard);
  const { count } = useSelector((state) => state.notifications);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  useEffect(() => {
    if (user) {
      user?.roles !== "ADMIN" && dispatch(getNotificationCount());
    }
    // dispatch(getCurrentUser());
  }, [user]);

  return (
    <div className='flex flex-col w-screen'>
      {/* <Navbar /> */}
      <div className='flex flex-row h-fit'>
        <ProSidebar />
        <div className='grow flex flex-col w-[500px] bg-[#e6e6e6] dark:bg-[#151718] overflow-y-scroll h-[calc(100vh_-_0px)]'>
          {" "}
          <div className='flex flex-row items-center w-full justify-between px-6 py-4'>
            <div className='flex flex-row gap-5 items-center'>
              <Button
                variant='flat'
                isIconOnly
                size='sm'
                className='bg-white dark:bg-zinc-800'
                onClick={() => dispatch(changeCollapsed())}
              >
                <Layout01Icon size={21} />
              </Button>
              <div className='mt-2'>
                <Breadcrumbs
                  variant='solid'
                  size='md'
                  separator='/'
                  itemClasses={{
                    separator: "px-2",
                  }}
                >
                  {breadcrumbs &&
                    breadcrumbs.map((bc) => (
                      <BreadcrumbItem href={bc?.link}>
                        {bc?.name}
                      </BreadcrumbItem>
                    ))}
                </Breadcrumbs>
              </div>
            </div>
            <div className='flex flex-row items-center gap-5'>
              <LightDarkSwitch />
              {user?.roles !== "ADMIN" ? (
                <Badge
                  color='danger'
                  size='lg'
                  content={count}
                  isInvisible={user?.roles === "ADMIN" || !count}
                  shape='circle'
                >
                  <Popover showArrow placement='bottom-end'>
                    <PopoverTrigger>
                      <Button
                        variant='solid'
                        isIconOnly
                        size='sm'
                        className='w-[36px] h-[36px] bg-white dark:bg-zinc-800 rounded-full p-[6px] border-[1px] dark:border-zinc-600'
                      >
                        <Notification03Icon />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className='p-1 border-[1px] border-neutral-100 dark:border-zinc-600'>
                      <NotificationsModal />
                      {/* <UserTwitterCard /> */}
                    </PopoverContent>
                  </Popover>
                </Badge>
              ) : (
                <Button
                  onClick={() => navigate("/notifications")}
                  variant='bordered'
                  isIconOnly
                  size='sm'
                  className='w-[36px] h-[36px] bg-white dark:bg-zinc-800 rounded-full p-[6px] border-[1px]'
                >
                  <Notification03Icon />
                </Button>
              )}
              <ProfileButton user={user} />
            </div>
          </div>
          <Routes>
            <Route path='/home' element={<HomePage />} />
            <Route path='/users' element={<UsersPage />} />
            <Route path='/notifications' element={<NotificationsPage />} />
            <Route path='/branches' element={<BranchesPage />} />
            <Route path='/chooseBranch' element={<ChooseBranchPage />} />
            <Route path='/agreements' element={<ReportPage />} />
            <Route path='/report' element={<HisobotPage />} />
            <Route
              path='/agreementsPayment/:id'
              element={<ReportPaymentsPage />}
            />
            <Route path='/employees/:id' element={<EmployeePage />} />
            <Route path='/employees' element={<EmployeesPage />} />
            <Route path='/profilePD' element={<ProfilePdPage />} />
            <Route path='/profilePD/pd/:id' element={<PDPage />} />
            <Route path='/profilePD/cd/:id' element={<CDPage />} />
            <Route path='/statistics' element={<StatisticsPage />} />
            <Route path='/profile' element={<ProfilePage />} />
            <Route path='/*' element={<Navigate to='/home' />} />
            {/* <Route path='/ads' element={<AdsPage />} />
            <Route path='/ads/:id' element={<AdsItemPage />} />
            <Route path='/incomes' element={<IncomesPage />} />
            <Route path='/outgoings' element={<OutgoingsPage />} />
            
            <Route path='/categories' element={<CategoriesPage />} />
          
            <Route path='/stores' element={<StoresPage />} />
            <Route path='/stores/:id' element={<StorePage />} />
            <Route path='/agreements' element={<AgreementsPage />} />
            <Route path='/agreements/rent/:id' element={<RentStorePage />} />
            <Route path='/agreements/sale/:id' element={<SaleStorePage />} />
            <Route path='/*' element={<Navigate to='/incomes' />} /> */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
