import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/Api";
import { findIndex } from "lodash";
import { toast } from "react-toastify";

export const getAllBranches = createAsyncThunk(
  "branches/getAll",
  async (body = {}) => {
    const response = await Api.get("/filial");
    return response.data;
  }
);

export const getBranch = createAsyncThunk("branches/get", async (body) => {
  const response = await Api.get(`/filial/${body}`);
  return response.data;
});

export const createBranch = createAsyncThunk(
  "branches/create",
  async (body) => {
    const response = await Api.post("/filial", body);
    return response.data;
  }
);

export const updateBranch = createAsyncThunk(
  "branches/update",
  async (body) => {
    const response = await Api.put(`/filial/${body?.id}`, body);
    return response.data;
  }
);

export const deleteBranch = createAsyncThunk(
  "branches/delete",
  async (body) => {
    const response = await Api.delete(`/filial/${body?.id}`);
    return response.data;
  }
);

const branchesSlice = createSlice({
  name: "branches",
  initialState: {
    branches: null,

    chosenBranch: null,

    branch: null,
    loading: false,
    uploadLoading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },

    chooseBranch: (state, action) => {
      state.chosenBranch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET branches ------------------/////
      .addCase(getAllBranches.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBranches.fulfilled, (state, action) => {
        state.loading = false;
        state.branches = action.payload?.content;
      })
      .addCase(getAllBranches.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET branch ------------------/////
      .addCase(getBranch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.branch = action.payload;
        state.monthlySalaries = action.payload?.monthlySalaries;
      })
      .addCase(getBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ CREATE branches ------------------/////
      .addCase(createBranch.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBranch.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.branches = [...state.branches, payload?.data];
        toast.success(payload?.message);
      })
      .addCase(createBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ delete branches ------------------/////
      .addCase(deleteBranch.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBranch.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.branches, { id: payload?.id });
        state.branches.splice(ctgIndex, 1);
      })
      .addCase(deleteBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE branches ------------------/////
      .addCase(updateBranch.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBranch.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.branches, { id: payload?.data?.id });
        // payload.itemCount = state.branches[ctgIndex]?.itemCount;
        state.branches[ctgIndex] = payload?.data;
        toast.success(payload?.message);
      })
      .addCase(updateBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError, chooseBranch } = branchesSlice.actions;

export default branchesSlice.reducer;
