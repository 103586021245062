import React from "react";

const Logo = ({ width = "50px", height = "50px", color = "#fff" }) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      id='Layer_2'
      data-name='Layer 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 173.51 82.31'
    >
      <g id='Layer_1-2' data-name='Layer 1'>
        <path d='M115.79,22.12c-4.28,4.22-8.41,8.29-12.95,12.76,8.79,8.73,17.68,17.55,27.04,26.84-1.5,.33-2.47,.73-3.45,.73-7.4,.05-14.81,.14-22.21-.08-1.46-.04-3.13-1.1-4.28-2.16-4.16-3.82-8.14-7.84-11.85-11.45-3.63,3.35-7.54,6.59-11,10.25-2.5,2.64-5.24,3.88-8.78,3.87-20.91-.03-41.83,0-62.74-.02-4.7,0-5.31-.66-5.3-5.31,0-3.08,.09-6.17-.03-9.25-.09-2.33,1.07-3.38,3.18-3.63,.99-.12,2-.09,2.99-.09,11.41,0,22.83,.03,34.24,.04,.83,0,1.71,.11,2.48-.12,.61-.18,1.45-.72,1.56-1.23,.11-.57-.34-1.64-.82-1.85-1.18-.51-2.51-.89-3.79-.91-6.58-.09-13.16,.03-19.75-.06-7.88-.1-14.12-3.42-17.75-10.48C-1,23.03-.92,15.83,3.22,9.02,6.41,3.8,11.64,1.38,17.38,.65,22.72-.02,28.17,.16,33.57,.13c1.06,0,2.38,.52,3.15,1.26,4.86,4.68,9.61,9.47,14.37,14.25,.43,.44,.67,1.07,1.26,2.04-1.32,.13-2.25,.29-3.18,.29-8.92,.02-17.83-.02-26.75,.05-1.2,0-2.48,.32-3.55,.85-.65,.33-.96,1.33-1.43,2.04,.68,.38,1.33,.98,2.06,1.09,1.22,.19,2.49,.03,3.74,.07,7.39,.26,14.81,.21,22.16,.91,9.44,.9,17.25,8.55,16.85,19.78-.01,.39,.08,.78,.09,.85,3.49-3.31,7.02-6.67,10.56-10.03C62.25,22.67,51.64,11.81,40.76,.67c1.38-.22,2.71-.59,4.03-.6,6.33-.06,12.66,0,19-.02,3.17,0,5.78,1.1,8.02,3.39,4.78,4.88,9.7,9.63,14.48,14.51,1.39,1.42,2.22,1.35,3.58-.11,4.65-5,9.5-9.81,14.19-14.79C106.02,.96,108.29,0,111.13,0c19.25,.03,38.49,.01,57.74,.02,3.8,0,4.38,.6,4.38,4.31,0,4.08,0,8.17,0,12.25,0,3.59-.3,3.9-3.82,3.9-9.92,0-19.83,0-29.75,0-.75,0-1.63-.15-2.2,.18-.57,.34-1.11,1.2-1.13,1.85-.02,.46,.77,1.14,1.34,1.38,.56,.24,1.31,.08,1.98,.08,9.92,0,19.83,0,29.75,0,3.45,0,3.83,.41,3.84,3.93,0,4.25,.01,8.5,0,12.75,0,3.14-.64,3.79-3.82,3.8-9.5,.02-19,0-28.5,0-4.77,0-4.76-.01-4.73,4.64,.02,3.5,.04,7-.03,10.5-.01,.68-.43,1.35-.66,2.03-.71-.3-1.57-.42-2.09-.91-4.96-4.7-9.86-9.46-14.79-14.19-1.98-1.9-2.86-4.24-2.85-6.95,0-5.82,0-11.64,0-17.45Z' />
        <g>
          <path d='M7.82,81.91c-.58,.27-1.26,.4-2.02,.4-.69,0-1.33-.11-1.92-.34-.59-.23-1.1-.55-1.53-.97-.43-.42-.77-.92-1.02-1.5-.25-.58-.37-1.23-.37-1.93s.12-1.37,.37-1.95,.59-1.08,1.03-1.49c.44-.41,.96-.72,1.55-.94,.59-.22,1.23-.33,1.92-.33,.63,0,1.26,.11,1.87,.34,.61,.22,1.11,.55,1.49,.98l-1.47,1.47c-.2-.28-.47-.49-.8-.62-.33-.14-.67-.2-1.01-.2-.38,0-.73,.07-1.05,.21s-.59,.33-.82,.58c-.23,.25-.41,.53-.53,.87-.13,.33-.19,.7-.19,1.1s.06,.78,.19,1.12c.13,.34,.3,.63,.53,.87,.22,.24,.49,.43,.81,.56s.66,.2,1.03,.2c.43,0,.81-.08,1.13-.25,.32-.17,.58-.39,.77-.66l1.51,1.42c-.4,.46-.89,.82-1.47,1.08Z' />
          <path d='M19.42,77.54c0,.71-.12,1.36-.37,1.95s-.59,1.09-1.02,1.51-.95,.74-1.55,.97c-.6,.23-1.25,.34-1.95,.34s-1.35-.11-1.95-.34c-.6-.23-1.11-.55-1.55-.97-.44-.42-.78-.92-1.02-1.51-.25-.59-.37-1.24-.37-1.95s.12-1.37,.37-1.95c.25-.58,.59-1.07,1.02-1.48,.44-.41,.95-.72,1.55-.94,.6-.22,1.25-.33,1.95-.33s1.35,.11,1.95,.33c.6,.22,1.12,.53,1.55,.94,.44,.41,.78,.9,1.02,1.48s.37,1.23,.37,1.95Zm-2.32,0c0-.39-.06-.75-.19-1.09-.13-.34-.3-.63-.53-.87-.22-.24-.49-.43-.81-.57-.32-.14-.67-.21-1.05-.21s-.73,.07-1.04,.21-.58,.33-.81,.57c-.23,.24-.4,.53-.53,.87-.12,.34-.18,.7-.18,1.09s.06,.78,.19,1.12c.13,.34,.3,.64,.53,.88,.22,.25,.49,.44,.81,.58,.31,.14,.66,.21,1.04,.21s.73-.07,1.04-.21c.31-.14,.58-.33,.81-.58,.23-.25,.41-.54,.53-.88,.13-.34,.19-.72,.19-1.12Z' />
          <path d='M26.56,82.07l-3.62-5.87h-.04l.05,5.87h-2.11v-8.98h2.47l3.6,5.86h.04l-.05-5.86h2.11v8.98h-2.46Z' />
          <path d='M35.62,75.42c-.19-.24-.43-.42-.73-.56s-.59-.21-.87-.21c-.14,0-.29,.01-.44,.04-.15,.03-.28,.07-.4,.15s-.22,.17-.3,.28c-.08,.11-.12,.26-.12,.44,0,.15,.03,.28,.1,.38s.16,.19,.28,.27c.12,.08,.27,.15,.44,.21,.17,.06,.36,.13,.57,.2,.3,.1,.62,.21,.95,.34,.33,.12,.63,.29,.9,.49,.27,.2,.49,.46,.67,.75,.18,.3,.27,.67,.27,1.12,0,.52-.1,.96-.29,1.34-.19,.38-.45,.69-.77,.93-.32,.25-.69,.43-1.1,.55-.42,.12-.84,.18-1.28,.18-.64,0-1.26-.11-1.87-.34-.6-.22-1.1-.54-1.5-.96l1.42-1.45c.22,.27,.51,.5,.87,.68,.36,.18,.72,.27,1.07,.27,.16,0,.32-.02,.47-.05s.29-.09,.4-.17c.11-.08,.21-.18,.27-.3,.07-.13,.1-.28,.1-.46s-.04-.31-.13-.43c-.08-.12-.21-.23-.36-.32-.16-.1-.35-.19-.58-.27-.23-.08-.5-.17-.79-.26-.29-.09-.57-.2-.84-.33-.28-.13-.52-.29-.74-.49-.22-.2-.39-.44-.52-.72-.13-.28-.2-.63-.2-1.03,0-.5,.1-.93,.3-1.28s.47-.65,.8-.88c.33-.23,.7-.4,1.12-.5,.42-.11,.83-.16,1.26-.16,.51,0,1.03,.09,1.55,.28s.99,.46,1.39,.82l-1.38,1.46Z' />
          <path d='M42.05,74.94v7.13h-2.17v-7.13h-2.54v-1.85h7.24v1.85h-2.54Z' />
          <path d='M50.38,82.07l-1.95-3.56h-.74v3.56h-2.12v-8.98h3.42c.43,0,.85,.04,1.26,.13s.78,.24,1.1,.45c.33,.21,.59,.49,.78,.84s.29,.78,.29,1.29c0,.61-.17,1.12-.5,1.54-.33,.41-.79,.71-1.37,.89l2.35,3.84h-2.54Zm-.09-6.23c0-.21-.04-.38-.13-.51-.09-.13-.2-.23-.34-.3-.14-.07-.3-.12-.47-.15-.17-.03-.34-.04-.5-.04h-1.16v2.09h1.03c.18,0,.36-.01,.55-.04,.19-.03,.36-.08,.51-.16,.15-.08,.28-.19,.38-.33,.1-.14,.15-.33,.15-.56Z' />
          <path d='M61.32,80.16c-.18,.44-.44,.82-.78,1.14-.34,.32-.75,.56-1.22,.74s-1.01,.27-1.59,.27-1.12-.09-1.6-.27-.88-.42-1.21-.74c-.33-.32-.58-.7-.76-1.14-.18-.44-.27-.94-.27-1.48v-5.59h2.16v5.42c0,.25,.03,.48,.1,.7,.06,.22,.16,.42,.29,.59,.13,.17,.3,.31,.52,.41,.22,.1,.48,.15,.78,.15s.56-.05,.78-.15c.21-.1,.39-.24,.53-.41,.13-.17,.23-.37,.29-.59,.06-.22,.09-.45,.09-.7v-5.42h2.17v5.59c0,.54-.09,1.03-.27,1.48Z' />
          <path d='M69.78,81.91c-.58,.27-1.26,.4-2.02,.4-.69,0-1.33-.11-1.92-.34-.59-.23-1.1-.55-1.53-.97-.43-.42-.77-.92-1.01-1.5s-.37-1.23-.37-1.93,.12-1.37,.37-1.95c.25-.58,.59-1.08,1.03-1.49,.44-.41,.96-.72,1.55-.94,.59-.22,1.23-.33,1.92-.33,.63,0,1.26,.11,1.87,.34s1.11,.55,1.49,.98l-1.47,1.47c-.2-.28-.47-.49-.8-.62s-.67-.2-1.01-.2c-.38,0-.73,.07-1.05,.21-.32,.14-.59,.33-.82,.58s-.41,.53-.53,.87-.19,.7-.19,1.1,.06,.78,.19,1.12c.13,.34,.3,.63,.53,.87,.22,.24,.49,.43,.81,.56s.66,.2,1.03,.2c.43,0,.81-.08,1.13-.25,.32-.17,.58-.39,.77-.66l1.51,1.42c-.4,.46-.89,.82-1.47,1.08Z' />
          <path d='M75.94,74.94v7.13h-2.17v-7.13h-2.54v-1.85h7.24v1.85h-2.54Z' />
          <path d='M79.47,82.07v-8.98h2.18v8.98h-2.18Z' />
          <path d='M92.86,77.54c0,.71-.12,1.36-.37,1.95s-.59,1.09-1.02,1.51-.95,.74-1.55,.97c-.6,.23-1.25,.34-1.95,.34s-1.35-.11-1.95-.34c-.6-.23-1.11-.55-1.55-.97s-.78-.92-1.02-1.51-.37-1.24-.37-1.95,.12-1.37,.37-1.95,.59-1.07,1.02-1.48c.44-.41,.95-.72,1.55-.94,.6-.22,1.25-.33,1.95-.33s1.35,.11,1.95,.33c.6,.22,1.12,.53,1.55,.94,.44,.41,.78,.9,1.02,1.48s.37,1.23,.37,1.95Zm-2.32,0c0-.39-.06-.75-.19-1.09-.13-.34-.3-.63-.53-.87-.22-.24-.5-.43-.81-.57-.32-.14-.67-.21-1.05-.21s-.73,.07-1.04,.21-.58,.33-.81,.57c-.23,.24-.4,.53-.53,.87-.12,.34-.18,.7-.18,1.09s.06,.78,.19,1.12c.13,.34,.3,.64,.53,.88,.22,.25,.49,.44,.81,.58,.31,.14,.66,.21,1.04,.21s.73-.07,1.04-.21c.31-.14,.58-.33,.81-.58,.23-.25,.41-.54,.53-.88,.13-.34,.19-.72,.19-1.12Z' />
          <path d='M100,82.07l-3.62-5.87h-.04l.05,5.87h-2.11v-8.98h2.47l3.6,5.86h.04l-.05-5.86h2.11v8.98h-2.46Z' />
          <path d='M113.92,81.91c-.58,.27-1.26,.4-2.02,.4-.69,0-1.33-.11-1.92-.34-.59-.23-1.1-.55-1.53-.97-.43-.42-.77-.92-1.01-1.5s-.37-1.23-.37-1.93,.12-1.37,.37-1.95c.25-.58,.59-1.08,1.03-1.49,.44-.41,.96-.72,1.55-.94,.59-.22,1.23-.33,1.92-.33,.63,0,1.26,.11,1.87,.34s1.11,.55,1.49,.98l-1.47,1.47c-.2-.28-.47-.49-.8-.62s-.67-.2-1.01-.2c-.38,0-.73,.07-1.05,.21-.32,.14-.59,.33-.82,.58s-.41,.53-.53,.87-.19,.7-.19,1.1,.06,.78,.19,1.12c.13,.34,.3,.63,.53,.87,.22,.24,.49,.43,.81,.56s.66,.2,1.03,.2c.43,0,.81-.08,1.13-.25,.32-.17,.58-.39,.77-.66l1.51,1.42c-.4,.46-.89,.82-1.47,1.08Z' />
          <path d='M125.52,77.54c0,.71-.12,1.36-.37,1.95s-.59,1.09-1.02,1.51-.95,.74-1.55,.97c-.6,.23-1.25,.34-1.95,.34s-1.35-.11-1.95-.34c-.6-.23-1.11-.55-1.55-.97s-.78-.92-1.02-1.51-.37-1.24-.37-1.95,.12-1.37,.37-1.95,.59-1.07,1.02-1.48c.44-.41,.95-.72,1.55-.94,.6-.22,1.25-.33,1.95-.33s1.35,.11,1.95,.33c.6,.22,1.12,.53,1.55,.94,.44,.41,.78,.9,1.02,1.48s.37,1.23,.37,1.95Zm-2.32,0c0-.39-.06-.75-.19-1.09-.13-.34-.3-.63-.53-.87-.22-.24-.5-.43-.81-.57-.32-.14-.67-.21-1.05-.21s-.73,.07-1.04,.21-.58,.33-.81,.57c-.23,.24-.4,.53-.53,.87-.12,.34-.18,.7-.18,1.09s.06,.78,.19,1.12c.13,.34,.3,.64,.53,.88,.22,.25,.49,.44,.81,.58,.31,.14,.66,.21,1.04,.21s.73-.07,1.04-.21c.31-.14,.58-.33,.81-.58,.23-.25,.41-.54,.53-.88,.13-.34,.19-.72,.19-1.12Z' />
          <path d='M135.1,82.07l.05-6.35h-.04l-2.33,6.35h-1.52l-2.27-6.35h-.04l.05,6.35h-2.04v-8.98h3.09l2.04,5.76h.05l1.96-5.76h3.14v8.98h-2.13Z' />
          <path d='M145.88,75.85c0,.52-.1,.97-.29,1.33s-.46,.65-.79,.87c-.33,.22-.71,.38-1.14,.48-.43,.1-.88,.15-1.34,.15h-1.08v3.39h-2.17v-8.98h3.3c.49,0,.95,.05,1.38,.15,.43,.1,.8,.25,1.12,.47,.32,.22,.57,.5,.75,.85,.18,.35,.27,.78,.27,1.3Zm-2.17,.01c0-.21-.04-.38-.13-.52s-.2-.24-.34-.32c-.14-.08-.31-.13-.49-.15s-.37-.04-.56-.04h-.95v2.12h.91c.2,0,.4-.02,.58-.05,.19-.03,.35-.09,.5-.18,.15-.08,.26-.2,.35-.34,.08-.14,.13-.31,.13-.53Z' />
          <path d='M152.52,82.07l-.7-1.76h-3.48l-.66,1.76h-2.36l3.77-8.98h2.11l3.73,8.98h-2.41Zm-2.41-6.58l-1.14,3.08h2.26l-1.12-3.08Z' />
          <path d='M161.38,82.07l-3.62-5.87h-.04l.05,5.87h-2.11v-8.98h2.47l3.6,5.86h.04l-.05-5.86h2.11v8.98h-2.46Z' />
          <path d='M170.06,78.26v3.81h-2.17v-3.81l-3.37-5.18h2.63l1.92,3.32,1.92-3.32h2.54l-3.45,5.18Z' />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
