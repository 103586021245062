import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowRightOnRectangleIcon,
  BuildingOffice2Icon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { sidebarMenus } from "./data";
import { uniqueId } from "lodash";
import { logout } from "../../pages/Login/authSlice";
import Logo from "../Logo";
import { Chip, Divider, Link } from "@nextui-org/react";
import { useTheme } from "next-themes";

const ProSidebar = () => {
  const { theme } = useTheme();
  const { chosenBranch } = useSelector((state) => state.branches);
  const { collapsed } = useSelector((state) => state.dashboard);
  // const [collapsed, setCollapsed] = useState(false);
  const currPath = useLocation().pathname;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className='relative h-[calc(100vh_-_0px)] flex flex-none '>
      {/* <button
        // onClick={() => setCollapsed(!collapsed)}
        className='absolute top-[130px] right-0 translate-x-[50%] z-50 bg-primary p-1 rounded-full'
      >
        {collapsed ? (
          <ChevronRightIcon className='w-[15px] stroke-[2px] stroke-white' />
        ) : (
          <ChevronLeftIcon className='w-[15px] stroke-[2px] stroke-white' />
        )}
      </button> */}
      <Sidebar
        breakPoint='xs'
        collapsed={collapsed}
        width={"250px"}
        rootStyles={{ overflow: "visible", zIndex: "1", border: "none" }}
        className='flex flex-col h-full gap-5 font-madefor bg-[#F6F6F6]'
      >
        <Menu
          className='flex flex-col h-full relative dark:bg-[#212324] border-none'
          menuItemStyles={{
            root: {
              fontSize: "14px",
              fontWeight: 400,
              border: "none",
            },
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  "&:hover": {
                    backgroundColor:
                      !collapsed && active
                        ? theme === "dark"
                          ? "#323232"
                          : "#dedede"
                        : !collapsed
                        ? theme === "dark"
                          ? "#313131"
                          : "#EAEAEA"
                        : "transparent",
                    color: theme === "dark" ? "#eee" : "#000",
                  },
                  //   padding: "30px",

                  color: theme === "dark" ? "#fff" : "#000",
                  backgroundColor:
                    !collapsed && active
                      ? theme === "dark"
                        ? "#323232"
                        : "#dfdfdf"
                      : undefined,
                };
            },
          }}
        >
          <div className='flex gap-2 flex-col justify-center items-center py-3 mx-5'>
            <div
              className='bg-primary w-fit p-2 rounded-2xl mt-3'
              style={{
                boxShadow:
                  theme === "light"
                    ? "5px 5px 10px #e2e2e2, -5px -5px 10px #ffffff"
                    : "5px 5px 10px #151617, -5px -5px 10px #2d3031",
              }}
            >
              {" "}
              <Logo width='32px' height='32px' />
            </div>
            <span
              onClick={() => navigate("chooseBranch")}
              // variant='light'
              // color='black'
              // radius='sm'
              className='text-[14px] leading-[13px] my-1 text-black dark:text-white font-black hover:cursor-pointer'
            >
              {chosenBranch ? (
                <span>{chosenBranch?.name}</span>
              ) : (
                <span className={collapsed ? "text-[12px]" : "text-[14px]"}>
                  {"Филиал танлаш"}
                </span>
              )}
            </span>
            {/* <img
              className={
                collapsed ? "w-[150px] rounded-lg shadow-xl" : "w-[150px]"
              }
              src={!collapsed ? logo1 : logo2}
            /> */}
          </div>

          {/* <div className='mt-3 w-full flex justify-center items-center'> */}
          {/* <MenuItem
              style={{ fontWeight: "bolder" }}
              key={uniqueId()}
              active={currPath.startsWith("/chooseBranch")}
              component={<NavLink to={"chooseBranch"} />}
              icon={<BuildingOffice2Icon className='w-[21px] stroke-[1.5px]' />}
            >
              {"Filial tanlash"}
            </MenuItem> */}

          {/* <Chip
              onClick={() => {
                navigate("chooseBranch");
              }}
              variant='faded'
              color='primary'
              radius='sm'
              className='text-[14px] hover:cursor-pointer'
            ></Chip> */}
          {/* </div> */}
          <Divider className='my-[1px]' />

          <div
            className={
              collapsed
                ? "flex flex-col justify-end items-center w-full gap-2 mt-2"
                : "flex flex-col justify-center items-center w-full gap-2 mt-2 p-2 p-auto"
            }
          >
            {sidebarMenus.map((el, index) => (
              <MenuItem
                style={{
                  // display: "flex",
                  // justifyContent: "center",
                  // justifyItems: "center",
                  // width: collapsed && "20px",
                  fontWeight: "bolder",
                  borderRadius: "13px",
                  // padding: "8px",
                }}
                key={uniqueId()}
                active={currPath.startsWith(el.pathName)}
                component={<NavLink to={el.pathName} />}
                icon={
                  <div
                    className={
                      collapsed &&
                      (currPath.startsWith(el.pathName)
                        ? "flex justify-center items-center p-[5px] bg-neutral-300 dark:bg-[#323232] rounded-2xl"
                        : "flex justify-center items-center p-[5px] hover:bg-[#EAEAEA] dark:hover:bg-[#292929] rounded-2xl")
                    }
                  >
                    <div className='p-2 bg-white dark:bg-[#424242] rounded-xl shadow-sm border-[0.3px] dark:border-neutral-600'>
                      {el.icon}
                    </div>
                  </div>
                }
              >
                <span>{el.name}</span>
              </MenuItem>
            ))}
          </div>

          <div
            style={{ justifyContent: collapsed ? "center" : "start" }}
            className='flex items-center absolute bottom-0 w-full px-8 py-5'
          >
            <button
              onClick={() => {
                dispatch(logout());
                navigate("/login");
              }}
              className='flex flex-row items-center gap-3'
            >
              <div className='p-2 bg-sakiyna-400 rounded-full text-primary dark:text-blue-400'>
                <ArrowRightOnRectangleIcon className='w-[20px] stroke-[1.8px]' />
              </div>
              {!collapsed && (
                <span className='text-[14px] font-bold dark:text-neutral-300'>
                  {"Чиқиш"}
                </span>
              )}
            </button>
          </div>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default ProSidebar;
