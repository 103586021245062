import * as Yup from "yup";

export const fields = [
  {
    name: "name",
    type: "text",
    label: "Номи",
    placeholder: "Номини киритинг...",
    isRequired: true,
  },
  {
    name: "description",
    type: "text",
    label: "Тавсифи",
    placeholder: "Тавсифини киритинг...",
    isRequired: true,
  },
  {
    name: "location",
    type: "text",
    label: "Локация",
    placeholder: "Локацияни киритинг...",
    isRequired: true,
  },
  {
    name: "salesDepartment",
    type: "number",
    label: "Савдо бўлими",
    placeholder: "Савдо бўлимини киритинг...",
    isRequired: true,
  },
];

export const emptyValues = {
  name: "",
  description: "",
  location: "",
  salesDepartment: "",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Бўш бўлмаслиги керак!"),
  description: Yup.string().required("Бўш бўлмаслиги керак!"),
  location: Yup.string().required("Бўш бўлмаслиги керак!"),
  salesDepartment: Yup.number().required("Бўш бўлмаслиги керак!"),
});

export const columns = [
  { name: "ID", uid: "id", sortable: true },
  // { name: "Avatar", uid: "profilePhoto", sortable: true },
  { name: "Номи", uid: "name", sortable: true },
  { name: "Тавсифи", uid: "description", sortable: true },
  { name: "Локация", uid: "location", sortable: true },
  { name: "Савдо бўлими", uid: "salesDepartment", sortable: true },
  // { name: "MAOSH", uid: "currentSalary", sortable: true },
  // { name: "MAOSH BERILISH VAQTI", uid: "salaryDate", sortable: true },

  { name: "Санаси", uid: "createdAt" },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "name",
  "description",
  "location",
  "salesDepartment",
  "createdAt",
  "actions",
];

export const searchIndexes = [
  "name",
  "description",
  "location",
  "salesDepartment",
];

export const searchIndexesChoose = [
  "name",
  "description",
  "location",
  "salesDepartment",
];
