import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/Api";
import { findIndex } from "lodash";
import { toast } from "react-toastify";

export const getAllUsers = createAsyncThunk(
  "users/getAll",
  async (body = {}) => {
    const response = await Api.get("/user");
    return response.data;
  }
);

export const getUser = createAsyncThunk("users/get", async (body) => {
  const response = await Api.get(`/user/${body}`);
  return response.data;
});

export const createUser = createAsyncThunk("users/create", async (body) => {
  const response = await Api.post("/user", body);
  return response.data;
});

export const updateUser = createAsyncThunk("users/update", async (body) => {
  const response = await Api.put(`/user/${body?.id}`, body);
  return response.data;
});

export const assignUserToBranch = createAsyncThunk(
  "users/assignUserToBranch",
  async (body) => {
    const response = await Api.put(
      `/filial/${body?.branchId}/assign-manager/${body?.userId}`
    );
    return response.data;
  }
);

export const updateUserSalary = createAsyncThunk(
  "users/updateSalary",
  async (body) => {
    const response = await Api.put(
      `/user/${body?.id}/update-salary?newSalary=${body?.newSalary}`,
      body
    );
    return response.data;
  }
);

export const deleteUser = createAsyncThunk("users/delete", async (body) => {
  const response = await Api.delete(`/user/${body?.id}`);
  return response.data;
});

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: null,
    monthlySalaries: null,
    salaryPayments: null,
    user: null,
    loading: false,
    uploadLoading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    setSalaryPayments: (state, { payload }) => {
      state.salaryPayments = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET users ------------------/////
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload?.content;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET user ------------------/////
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.monthlySalaries = action.payload?.monthlySalaries;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ CREATE users ------------------/////
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.users = [...state.users, payload?.data];
        toast.success(payload?.message);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ delete users ------------------/////
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.users, { id: payload?.id });
        state.users.splice(ctgIndex, 1);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE users ------------------/////
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.users, { id: payload?.data?.id });
        state.users[ctgIndex] = payload?.data;
        toast.success(payload?.message);
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE users ------------------/////
      .addCase(assignUserToBranch.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignUserToBranch.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.users, { id: payload?.id });
        state.users[ctgIndex] = payload;
      })
      .addCase(assignUserToBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE user Salary ------------------/////
      .addCase(updateUserSalary.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserSalary.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload;

        state.user.salaryChanges.sort(
          (a, b) => new Date(b.changeDate) - new Date(a.changeDate)
        );
      })
      .addCase(updateUserSalary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError, setSalaryPayments } = usersSlice.actions;

export default usersSlice.reducer;
