import * as Yup from "yup";

export const salaryFields = [
  {
    name: "newSalary",
    type: "number",
    label: "Янги маош",
    placeholder: "Янги маошни киритинг...",
  },
];

export const salaryEmptyValues = {
  newSalary: 0,
};

export const salaryValidationSchema = Yup.object().shape({
  newSalary: Yup.number().required("Бўш бўлмаслиги керак!"),
});

export const fields = [
  {
    name: "name",
    type: "text",
    label: "Исми",
    placeholder: "Исмини киритинг...",
    isRequired: true,
  },
  {
    name: "surname",
    type: "text",
    label: "Фамилия",
    placeholder: "Фамилияни киритинг...",
    isRequired: true,
  },
  {
    name: "jobDescription",
    type: "text",
    label: "Касби",
    placeholder: "Касбини киритинг...",
  },
  {
    name: "currentSalary",
    isRequired: true,
    type: "text",
    label: "Жорий маош",
    placeholder: "Жорий маошни киритинг...",
  },
  {
    name: "filialId",
    type: "customSelect",
    label: "Филиал",
    placeholder: "Филиални танланг...",
    isRequired: true,
  },
];

export const emptyValues = {
  name: "",
  surname: "",
  currentSalary: "",
  jobDescription: "",
  filialId: "",

  // currentSalary: 0,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Бўш бўлмаслиги керак!"),
  surname: Yup.string().required("Бўш бўлмаслиги керак!"),
  jobDescription: Yup.string().required("Бўш бўлмаслиги керак!"),
  currentSalary: Yup.string()
    .matches(/^\d+$/, "Илтимос рақам киритинг!")
    .required("Бўш бўлмаслиги керак!"),

  filialId: Yup.mixed().required("Бўш бўлмаслиги керак!"),
});

export const columns = [
  { name: "ID", uid: "id", sortable: true },
  { name: "Аватар", uid: "profilePhoto", sortable: true },
  { name: "Фамилияси", uid: "surname", sortable: true },
  { name: "Исми", uid: "name", sortable: true },
  { name: "Касби", uid: "jobDescription", sortable: true },
  { name: "Маош", uid: "currentSalary", sortable: true },
  // { name: "MAOSH BERILISH VAQTI", uid: "salaryDate", sortable: true },

  { name: "Санаси", uid: "createdAt" },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "name",
  "profilePhoto",
  "surname",
  "currentSalary",
  "jobDescription",
  "salaryDate",
  "createdAt",
  "actions",
];

export const searchIndexes = [
  "name",
  "surname",
  "currentSalary",
  "jobDescription",
  "salaryDate",
];
