import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import Api from "../../config/Api";
import { toast } from "react-toastify";
import Api from "../../config/Api";

export const getUserTotal = createAsyncThunk(
  "dashboard/getUserTotal",
  async (body) => {
    const response = await Api.get(`/dashboard/userTotal`);
    return response.data;
  }
);

export const getFilialTotal = createAsyncThunk(
  "dashboard/getFilialTotal",
  async (body) => {
    const response = await Api.get(`/dashboard/filiallTotal`);
    return response.data;
  }
);

export const getTotalIncomeOutcome = createAsyncThunk(
  "dashboard/getTotalIncomeOutcome",
  async (body) => {
    const response = await Api.get(`/dashboard/total-income-outcome`);
    return response.data;
  }
);

export const getMostIncome = createAsyncThunk(
  "dashboard/getMostIncome",
  async (body) => {
    const response = await Api.get(
      `/dashboard/most-income?fromDate=${body?.startDate}&toDate=${body?.endDate}`
    );
    return response.data;
  }
);

export const getMostOutcome = createAsyncThunk(
  "dashboard/getMostOutcome",
  async (body) => {
    const response = await Api.get(
      `/dashboard/most-outcome?fromDate=${body?.startDate}&toDate=${body?.endDate}`
    );
    return response.data;
  }
);

export const getLeastIncome = createAsyncThunk(
  "dashboard/getLeastIncome",
  async (body) => {
    const response = await Api.get(
      `/dashboard/least-income?fromDate=${body?.startDate}&toDate=${body?.endDate}`
    );
    return response.data;
  }
);

export const getLeastOutcome = createAsyncThunk(
  "dashboard/getLeastOutcome",
  async (body) => {
    const response = await Api.get(
      `/dashboard/least-outcome?fromDate=${body?.startDate}&toDate=${body?.endDate}`
    );
    return response.data;
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    breadcrumbs: null,
    totalIncome: null,
    totalOutcome: null,
    userTotal: null,
    filialTotal: null,

    mostOutcome: null,
    mostIncome: null,
    leastIncome: null,
    leastOutcome: null,

    isLoggedIn: false,
    loading: false,
    error: null,
    collapsed: localStorage.getItem("collapsed") === "true" ? true : false,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    changeBC: (state, { payload }) => {
      state.breadcrumbs = payload;
    },
    changeCollapsed: (state, { payload }) => {
      localStorage.setItem("collapsed", !state.collapsed ? "true" : "false");
      state.collapsed = !state.collapsed;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getUserTotal.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserTotal.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userTotal = payload;
      })
      .addCase(getUserTotal.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(getFilialTotal.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFilialTotal.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.filialTotal = payload;
      })
      .addCase(getFilialTotal.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(getTotalIncomeOutcome.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTotalIncomeOutcome.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.totalIncome = payload?.totalIncome;
        state.totalOutcome = payload?.totalOutcome;
      })
      .addCase(getTotalIncomeOutcome.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(getMostIncome.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMostIncome.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.mostIncome = payload;
      })
      .addCase(getMostIncome.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getMostOutcome.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMostOutcome.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.mostOutcome = payload;
      })
      .addCase(getMostOutcome.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(getLeastIncome.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLeastIncome.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.leastIncome = payload;
      })
      .addCase(getLeastIncome.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getLeastOutcome.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLeastOutcome.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.leastOutcome = payload;
      })
      .addCase(getLeastOutcome.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { resetError, changeBC, changeCollapsed } = dashboardSlice.actions;

export default dashboardSlice.reducer;
