import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/Api";
import { findIndex } from "lodash";
import { toast } from "react-toastify";

export const getAllProfilePDs = createAsyncThunk(
  "profilePDs/getAll",
  async (body = {}) => {
    const response = await Api.get("/profilePD");
    return response.data;
  }
);

export const getAllProfilePDsBranch = createAsyncThunk(
  "profilePDs/getAllBranch",
  async (body = {}) => {
    const response = await Api.get(`/profilePD/filial`);
    return response.data;
  }
);

export const getAllProfilePDsByBranchId = createAsyncThunk(
  "profilePDs/getAllbyBranchId",
  async (body = {}) => {
    const response = await Api.get(`/profilePD/filial/${body}`);
    return response.data;
  }
);

export const getProfilePDById = createAsyncThunk(
  "profilePDs/getById",
  async (body = {}) => {
    const response = await Api.get(`/profilePD/${body}`);
    return response.data;
  }
);

export const getProfilePDRemainingById = createAsyncThunk(
  "profilePDs/getRemainingById",
  async (body = {}) => {
    const response = await Api.get(
      `/purchasingDepartment/remaining-payment/${body}`
    );
    return response.data;
  }
);

export const getProfilePDTotalById = createAsyncThunk(
  "profilePDs/getTotalById",
  async (body = {}) => {
    const response = await Api.get(`/purchasingDepartment/full-amount/${body}`);
    return response.data;
  }
);

export const getPDsById = createAsyncThunk(
  "profilePDs/getProfilePDPsById",
  async (body = {}) => {
    const response = await Api.get(`/purchasingDepartment/profilePD/${body}`);
    return response.data;
  }
);

export const createProfilePD = createAsyncThunk(
  "profilePDs/create",
  async (body) => {
    const response = await Api.post("/profilePD", body);
    return response.data;
  }
);

export const updateProfilePD = createAsyncThunk(
  "profilePDs/update",
  async (body) => {
    const response = await Api.put(`/profilePD/${body?.id}`, body);
    return response.data;
  }
);

export const deleteProfilePD = createAsyncThunk(
  "profilePDs/delete",
  async (body) => {
    const response = await Api.delete(`/profilePD/${body?.id}`);
    return response.data;
  }
);

export const uploadImage = createAsyncThunk(
  "profilePDs/uploadImage",
  async (body, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("file", body?.file);
    try {
      const response = await Api.post("/fayl/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      let reqBody = {
        fileEntityId: response?.data?.id,
        ...body?.profilePDData,
      };
      const res = await Api.put(`/profilePD/${reqBody?.id}`, reqBody);

      body?.removeSelection();
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createPD = createAsyncThunk(
  "profilePDs/createPD",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/purchasingDepartment`, body);
      // body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePD = createAsyncThunk(
  "profilePDs/updatePD",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.put(`/purchasingDepartment/${body?.id}`, body);
      // body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePD = createAsyncThunk(
  "profilePDs/deletePD",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`/purchasingDepartment/${+body?.id}`);
      // body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const profilePDsSlice = createSlice({
  name: "profilePDs",
  initialState: {
    profilePDs: null,
    pds: null,
    profilePD: null,
    loading: false,
    total: null,
    remaining: null,
    uploadLoading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    clearDatas: (state) => {
      state.profilePD = null;
      state.profilePDs = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET profilePDs ------------------/////
      .addCase(getAllProfilePDs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProfilePDs.fulfilled, (state, action) => {
        state.loading = false;
        state.profilePDs = action.payload?.content;
      })
      .addCase(getAllProfilePDs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profilePDs ------------------/////
      .addCase(getAllProfilePDsBranch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProfilePDsBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.profilePDs = action.payload?.content;
      })
      .addCase(getAllProfilePDsBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profilePDs ------------------/////
      .addCase(getAllProfilePDsByBranchId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProfilePDsByBranchId.fulfilled, (state, action) => {
        state.loading = false;
        state.profilePDs = action.payload?.content;
      })
      .addCase(getAllProfilePDsByBranchId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profilePDs ------------------/////
      .addCase(getPDsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPDsById.fulfilled, (state, action) => {
        state.loading = false;
        state.pds = action.payload;
      })
      .addCase(getPDsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profilePDs ------------------/////
      .addCase(getProfilePDById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfilePDById.fulfilled, (state, action) => {
        state.loading = false;
        state.profilePD = action.payload;
      })
      .addCase(getProfilePDById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profilePDs ------------------/////
      .addCase(getProfilePDTotalById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfilePDTotalById.fulfilled, (state, action) => {
        state.loading = false;
        state.total = action.payload;
      })
      .addCase(getProfilePDTotalById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ GET profilePDs ------------------/////
      .addCase(getProfilePDRemainingById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfilePDRemainingById.fulfilled, (state, action) => {
        state.loading = false;
        state.remaining = action.payload;
      })
      .addCase(getProfilePDRemainingById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ CREATE profilePDs ------------------/////
      .addCase(createProfilePD.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProfilePD.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.profilePDs = [payload?.data, ...state.profilePDs];
        toast.success(payload?.message);
      })
      .addCase(createProfilePD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ delete profilePDs ------------------/////
      .addCase(deleteProfilePD.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProfilePD.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.profilePDs, { id: payload?.data?.id });
        state.profilePDs.splice(ctgIndex, 1);
        toast.success(payload?.message);
      })
      .addCase(deleteProfilePD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE profilePDs ------------------/////
      .addCase(updateProfilePD.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfilePD.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.profilePDs, { id: payload?.data?.id });
        state.profilePDs[ctgIndex] = payload?.data;
        toast.success(payload?.message);
      })
      .addCase(updateProfilePD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE profilePDs ------------------/////
      .addCase(uploadImage.pending, (state) => {
        state.uploadLoading = true;
      })
      .addCase(uploadImage.fulfilled, (state, { payload }) => {
        state.uploadLoading = false;
        const ctgIndex = findIndex(state.profilePDs, { id: payload?.id });
        payload.itemCount = state.profilePDs[ctgIndex]?.itemCount;
        state.profilePDs[ctgIndex] = payload;
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.uploadLoading = false;
        state.error = action.payload;
      })

      ///------------ CREATE profilePD pD ------------------/////
      .addCase(createPD.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPD.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.pds = [...state.pds, payload?.data];
        toast.success(payload?.message);
      })
      .addCase(createPD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ UPDATE profilePD pD ------------------/////
      .addCase(updatePD.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePD.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.pds, {
          id: payload?.data?.id,
        });
        state.pds[ctgIndex] = payload?.data;
        toast.success(payload?.message);
      })
      .addCase(updatePD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      ///------------ delete pD ------------------/////
      .addCase(deletePD.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePD.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.pds, {
          id: payload?.data?.id,
        });
        state.pds.splice(ctgIndex, 1);
        toast.success(payload?.message);
      })
      .addCase(deletePD.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetError, clearDatas } = profilePDsSlice.actions;

export default profilePDsSlice.reducer;
