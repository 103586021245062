import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options = {
//   elements: {
//     line: {
//       tension: 0.19,
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//       labels: {
//         font: {
//           size: 12,
//           weight: 500,
//         },
//         color: "#2D3748",
//         boxWidth: 8,
//         boxHeight: 8,
//         usePointStyle: true,
//         pointStyle: "circle",
//       },
//     },
//     // title: {
//     //   display: true,
//     //   text: "Chart.js Line Chart",
//     // },
//   },
// };

const LineChart = ({ datas, options }) => {
  useEffect(() => {
    console.log("Render");
  }, [datas]);
  return <Line options={options} data={datas} className='w-full mb-10' />;
};

export default LineChart;
