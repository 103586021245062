import * as Yup from "yup";

export const columns = [
  { name: "ID", uid: "id", sortable: true },
  { name: "Номи", uid: "entityName", sortable: true },
  { name: "Тўлов миқдори", uid: "amount" },
  { name: "Тури", uid: "type", sortable: true },
  { name: "Адтел закуп", uid: "classification", sortable: true },

  { name: "Санаси", uid: "createdAt" },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "entityName",
  "amount",
  "classification",
  "type",

  "createdAt",
  "actions",
];

export const searchIndexes = ["entityName", "amount", "classification"];
