import React, { useCallback, useEffect } from "react";
import {
  RadioGroup,
  Radio,
  cn,
  Input,
  CircularProgress,
  CheckboxGroup,
  Checkbox,
} from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { chooseBranch, getAllBranches } from "./branchesSlice";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import * as JsSearch from "js-search";
import { searchIndexesChoose } from "./data";
import { changeBC } from "../DashboardPage/dashboardSlice";

export const CustomRadio = (props) => {
  const { children, ...otherProps } = props;

  return (
    <Checkbox
      {...otherProps}
      classNames={{
        base: cn(
          " m-0 bg-content1 hover:bg-content2 items-center justify-between",
          "flex-row-reverse max-w-full cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent",
          "data-[selected=true]:border-primary"
        ),
      }}
    >
      {children}
    </Checkbox>
  );
};

const ChooseBranchPage = () => {
  const dispatch = useDispatch();
  const [branchesData, setBranchesData] = React.useState([]);

  const { branches, loading, chosenBranch } = useSelector(
    (state) => state.branches
  );
  const [selected, setSelected] = React.useState(
    chosenBranch ? [chosenBranch?.id] : []
  );

  const [filterValue, setFilterValue] = React.useState("");

  const hasSearchFilter = Boolean(filterValue);

  useEffect(() => {
    dispatch(changeBC([{ name: "Филиал танлаш", link: "/chooseBranch" }]));

    dispatch(getAllBranches());
  }, []);

  useEffect(() => {
    setBranchesData(branches);
  }, [branches]);

  useEffect(() => {
    if (branches) {
      let filteredDatas = [...branches];

      if (hasSearchFilter) {
        var search = new JsSearch.Search("id");

        search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
        search.searchIndex = new JsSearch.UnorderedSearchIndex();
        JsSearch.StopWordsMap.bob = true;

        searchIndexesChoose.map((element) => {
          search.addIndex(element);
        });

        search.addDocuments([...branches]);

        filteredDatas = search.search(filterValue);
      }
      setBranchesData(filteredDatas);
    }
  }, [filterValue]);

  const onSearchChange = useCallback((value) => {
    if (value) {
      setFilterValue(value);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
  }, []);

  return (
    <div className='w-full px-5 gap-2 flex flex-col justify-center items-center'>
      <CheckboxGroup
        value={selected}
        onChange={(e) => {
          let selKey = e.pop();
          setSelected([selKey]);
          dispatch(chooseBranch(branches.find((el) => el.id == +selKey)));
          // console.log(branches.find((el) => el.id == +e.target.value));
        }}
        className='flex flex-col p-5 w-full bg-neutral-100 dark:bg-[#101112] rounded-3xl gap-5 border-[0.5px] dark:border-neutral-800'
        // label='Filiallar'
        // description='Selected plan can be changed at any time.'
      >
        <div className='flex flex-row items-start justify-between w-full'>
          <span className='text-[14px]'>{"Филиаллар"}</span>
          <Input
            radius='lg'
            variant='faded'
            size='md'
            isClearable
            className='w-[400px] font-semibold'
            placeholder='Қидириш...'
            startContent={<MagnifyingGlassIcon className='w-[18px]' />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
        </div>
        <div
          className={
            branchesData
              ? "grid grid-cols-2 w-full gap-5"
              : "flex justify-center items-center"
          }
        >
          {branchesData ? (
            branchesData.map((br) => (
              <CustomRadio
                key={br?.id}
                description={br?.description}
                value={br?.id}
              >
                {br?.name}
              </CustomRadio>
            ))
          ) : (
            <CircularProgress />
          )}
        </div>
      </CheckboxGroup>
    </div>
  );
};

export default ChooseBranchPage;
