import React, { useEffect, useState } from "react";
import { dailyData, monthlyData } from "../../utils/statistics";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { changeBC } from "../DashboardPage/dashboardSlice";
import {
  getAllIncomesByDateRange,
  getAllIncomesByDateRangeManager,
  getAllOutcomesByDateRange,
  getAllOutcomesByDateRangeManager,
} from "./statisticsSlice";
import { Tab, Tabs, DateRangePicker } from "@nextui-org/react";
import LineChart from "../../components/Statistics/LineChart";
import BarChart from "../../components/Statistics/BarChart";
import {
  Analytics01Icon,
  ChartHistogramIcon,
  ChartLineData01Icon,
} from "hugeicons-react";
import { useTheme } from "next-themes";
import { getOptions } from "./data";
import { parseDate } from "@internationalized/date";
import { FormatDate } from "../../utils/date";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const setChartDatas = (labels, data1, data2) => ({
  labels: [...labels],
  datasets: [
    {
      label: "Кирим",
      data: data1,
      borderColor: "#19A24A",
      backgroundColor: "#8ac926",
      borderWidth: 2,
      borderRadius: 13,
    },
    {
      label: "Чиқим",
      data: data2,
      borderColor: "#ffb703",
      backgroundColor: "#fb8500",
      borderWidth: 2,
      borderRadius: 13,
    },
  ],
});

const StatisticsPage = () => {
  const { theme } = useTheme();
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeBC([{ name: "Статистика", link: "/statistics" }]));
  }, []);

  const [selectedKey, setSelectedKey] = useState("monthly");
  const [selectedKeyChart, setSelectedKeyChart] = useState("line");
  const [labels, setLabels] = useState(new Set([]));
  const [datas, setDatas] = useState(null);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [dates, setDates] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  });

  const { outcomes, incomes } = useSelector((state) => state.statistics);

  useEffect(() => {
    let fromDate = new Date();
    let toDate = new Date();
    fromDate.setDate(toDate.getDate() - 7);
    setDates((prev) => {
      prev.fromDate = fromDate;
      prev.toDate = toDate;
      return prev;
    });
    if (user) {
      if (user?.roles === "ADMIN") {
        dispatch(
          getAllIncomesByDateRange({
            startDate: fromDate.toISOString(),
            endDate: toDate.toISOString(),
          })
        );
        dispatch(
          getAllOutcomesByDateRange({
            startDate: fromDate.toISOString(),
            endDate: toDate.toISOString(),
          })
        );
      } else {
        dispatch(
          getAllIncomesByDateRangeManager({
            startDate: fromDate.toISOString(),
            endDate: toDate.toISOString(),
          })
        );
        dispatch(
          getAllOutcomesByDateRangeManager({
            startDate: fromDate.toISOString(),
            endDate: toDate.toISOString(),
          })
        );
      }
    }
  }, [user]);

  useEffect(() => {
    if (data1 && data2 && labels) {
      setDatas(setChartDatas(Array.from(labels), data1, data2));
    }
  }, [data1, data2, labels]);

  useEffect(() => {
    if (incomes && outcomes) {
      if (selectedKey == "monthly") {
        insertDatas(monthlyData(incomes), monthlyData(outcomes));
      } else if (selectedKey == "daily") {
        insertDatas(dailyData(incomes), dailyData(outcomes));
      }
    }
  }, [incomes, outcomes, selectedKey]);
  const insertDatas = (data1Object, data2Object) => {
    console.log(Object.keys(data1Object));
    console.log(Object.keys(data2Object));
    let keysArray = [
      ...new Set([
        ...Object.keys(data1Object).reverse(),
        ...Object.keys(data2Object).reverse(),
      ]),
    ];
    setLabels(keysArray);
    let data1 = [];
    let data2 = [];

    keysArray.forEach((key) => {
      if (key in data1Object) {
        data1.push(data1Object[key]);
      } else {
        data1.push(0);
      }

      if (key in data2Object) {
        data2.push(data2Object[key]);
      } else {
        data2.push(0);
      }
    });

    setData2([...data2]);

    setData1([...data1]);
  };

  return (
    <div className='flex flex-col w-full h-fit px-8 gap-8'>
      {/* <div className='flex flex-row w-full gap-8 items-center'>
        <span className='flex flex-col w-fit items-center bg-red-200/50 text-red-600 rounded-xl px-8 py-3'>
          Chiqim{" "}
          <span className='font-bold'>{`${
            outcomeTotalPrice == 0
              ? 0
              : outcomeTotalPrice
              ? getMoneyPattern(outcomeTotalPrice)
              : ""
          } so'm`}</span>
        </span>
        <span className='flex flex-col w-fit items-center bg-green-200/50 text-green-600 rounded-xl px-8 py-3'>
          Kirim{" "}
          <span className='font-bold'>{`${
            incomeTotalPrice == 0
              ? 0
              : incomeTotalPrice
              ? getMoneyPattern(incomeTotalPrice)
              : ""
          } so'm`}</span>
        </span>
      </div> */}
      <div className='flex flex-col w-full p-3 bg-white dark:bg-[#101112] border-[0.5px] dark:border-neutral-800 rounded-xl gap-5 mb-5'>
        {/* <span className='font-bold text-[18px] text-primary'>
          {"Кирим/Чиқим статистикаси"}
        </span> */}

        <div className='flex flex-row justify-between'>
          <Tabs color='primary' onSelectionChange={setSelectedKey}>
            <Tab key='monthly' title='Ойлик' />
            <Tab key='daily' title='Кунлик' />
          </Tabs>
          <Tabs color='primary' onSelectionChange={setSelectedKeyChart}>
            <Tab
              key='line'
              title={
                <div>
                  <ChartLineData01Icon />
                </div>
              }
            />
            <Tab
              key='bar'
              title={
                <div>
                  <Analytics01Icon />
                </div>
              }
            />
          </Tabs>
          <div className='flex flex-row items-center gap-5'>
            <DateRangePicker
              defaultValue={{
                start: parseDate(
                  FormatDate(
                    (() => {
                      let dt = new Date();
                      dt.setDate(dt.getDate() - 7);
                      return dt;
                    })()
                  )
                ),
                end: parseDate(FormatDate(new Date())),
              }}
              visibleMonths={2}
              label='Вақт оралиғини танлаш'
              //   value={value}
              onChange={(date) => {
                if (date) {
                  //   dispatch(clearIncomes());
                  //   dispatch(clearOutcomes());
                  if (user?.roles === "ADMIN") {
                    dispatch(
                      getAllIncomesByDateRange({
                        startDate: new Date(date?.start).toISOString(),
                        endDate: new Date(date?.end).toISOString(),
                      })
                    );
                    dispatch(
                      getAllOutcomesByDateRange({
                        startDate: new Date(date?.start).toISOString(),
                        endDate: new Date(date?.end).toISOString(),
                      })
                    );
                  } else {
                    dispatch(
                      getAllIncomesByDateRangeManager({
                        startDate: new Date(date?.start).toISOString(),
                        endDate: new Date(date?.end).toISOString(),
                      })
                    );
                    dispatch(
                      getAllOutcomesByDateRangeManager({
                        startDate: new Date(date?.start).toISOString(),
                        endDate: new Date(date?.end).toISOString(),
                      })
                    );
                  }
                } else {
                  //   dispatch(getAllIncomes());
                  //   dispatch(getAllOutcomes());
                }
              }}
            />
            {/* <DateRangePicker
              placement='bottomEnd'
              onChange={(date) => {
                if (date) {
                  //   dispatch(clearIncomes());
                  //   dispatch(clearOutcomes());
                  dispatch(
                    getAllIncomesByDateRange({
                      startDate: date[0].toISOString(),
                      endDate: date[1].toISOString(),
                    })
                  );
                  dispatch(
                    getAllOutcomesByDateRange({
                      startDate: date[0].toISOString(),
                      endDate: date[1].toISOString(),
                    })
                  );
                } else {
                  //   dispatch(getAllIncomes());
                  //   dispatch(getAllOutcomes());
                }
              }}
            /> */}
          </div>
        </div>

        {
          datas &&
            labels &&
            data1 &&
            data2 &&
            (selectedKeyChart === "line" ? (
              <LineChart datas={datas} options={getOptions(theme)} />
            ) : (
              <BarChart datas={datas} options={getOptions(theme)} />
            ))
          //   <Bar options={options} data={datas} className='w-full mb-10' />
        }
      </div>
    </div>
  );
};

export default StatisticsPage;
