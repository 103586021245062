import * as Yup from "yup";

export const fieldsSalaryMonth = [
  {
    name: "paymentAmount",
    type: "number",
    label: "Тўлов қиймати",
    placeholder: "Тўлов қийматини киритинг...",
    isRequired: true,
  },
  {
    name: "paidAmount",
    type: "number",
    label: "Тўланган қиймат",
    placeholder: "Тўланган қийматни киритинг...",
    isRequired: true,
  },
  {
    name: "monthDate",
    type: "date",
    label: "Ой",
    placeholder: "Ойни танланг...",
    isRequired: true,
  },
];

export const emptyValuesSalaryMonth = {
  paymentAmount: "",
  paidAmount: "",
  monthDate: "",
};

export const validationSchemaSalaryMonth = Yup.object().shape({
  paymentAmount: Yup.number().required("Бўш бўлмаслиги керак!"),
  paidAmount: Yup.number().required("Бўш бўлмаслиги керак!"),
  monthDate: Yup.string().required("Бўш бўлмаслиги керак!"),
});

export const columns = [
  { name: "ID", uid: "id", sortable: true },
  { name: "Ой", uid: "monthDate", sortable: true },
  { name: "Тўлов қиймати", uid: "paymentAmount", sortable: true },
  { name: "Статус", uid: "paymentStatus", sortable: true },
  { name: "Прогресс", uid: "paymentProgress", sortable: true },
  { name: "Тўланган қиймати", uid: "paidAmount", sortable: true },
  { name: "Қарздорлик қиймати", uid: "debtAmount", sortable: true },

  { name: "Санаси", uid: "createdAt", sortable: true },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "paymentAmount",
  "paidAmount",
  "debtAmount",
  "paymentStatus",
  "paymentProgress",

  "monthDate",
  "createdAt",
  "actions",
];

export const searchIndexes = ["newPayment", "createdAt"];
