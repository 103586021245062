import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBranches } from "../BranchesPage/branchesSlice";
import {
  createReport,
  deleteReport,
  getAllReport,
  getAllReportBranch,
  getAllReportByBranchId,
  updateReport,
} from "./reportSlice";
import {
  INITIAL_VISIBLE_COLUMNS,
  columns,
  emptyValues,
  fields,
  searchIndexes,
  validationSchema,
} from "./data";
import ProTable from "../../components/ProTable";
import { changeBC } from "../DashboardPage/dashboardSlice";
import { useTheme } from "next-themes";
import { BASE_URL } from "../../config/Api";

const ReportPage = () => {
  const { branches, chosenBranch } = useSelector((state) => state.branches);
  const { user } = useSelector((state) => state.auth);
  const { reports } = useSelector((state) => state.report);
  const { theme, setTheme } = useTheme();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeBC([{ name: "Шартномалар", link: "/report" }]));

    dispatch(getAllBranches());
  }, []);
  useEffect(() => {
    if (user) {
      // if (user?.roles === "MANAGER") {
      //   dispatch(getAllReportByBranchId(user?.assignedFilialId));
      // } else
      if (user?.roles === "ADMIN") {
        if (chosenBranch) {
          dispatch(getAllReportByBranchId(chosenBranch?.id));
        } else {
          dispatch(getAllReport());
        }
      } else {
        dispatch(getAllReportBranch());
      }
    }
  }, [user]);
  return (
    <div className='flex flex-col w-full px-5 gap-8 h-full'>
      <div className='tableContent'>
        <div className='flex flex-col w-full items-center gap-5'>
          {reports && branches && (
            <ProTable
              exportData={{
                link: chosenBranch
                  ? `/report/export/${chosenBranch?.id}`
                  : `/report/export`,
                fileName: "export_report.xlsx",
              }}
              tableName='Шартнома'
              searchIndexes={searchIndexes}
              viewButtonUrl='/agreementsPayment'
              createSubmitHandler={(reqBody) =>
                dispatch(
                  createReport({ ...reqBody, filialId: +reqBody?.filialId })
                )
              }
              editSubmitHandler={(reqBody) => {
                dispatch(updateReport({ ...reqBody }));
              }}
              deleteSubmitHandler={(id) => dispatch(deleteReport({ id }))}
              columns={columns}
              initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
              tableData={reports}
              createData={{
                fields: fields.map((el) => {
                  if (el.name === "filialId") {
                    el.selectData = branches.map((el) => ({
                      name: el?.name,
                      ...el,
                    }));
                    return el;
                  }

                  return el;
                }),
                initialValues: emptyValues,
                validationSchema,
              }}
              editData={{
                fields,
                initialValues: (data) => ({
                  filialId: +data?.filial?.id,
                  ...data,
                }),
                validationSchema,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
